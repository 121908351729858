export default [
    {
      header: 'Biletler',
      resource: 'ticket_title',
      action: 'read',
    },
    {
      title: 'Tüm Biletler',
      route: 'all-tickets',
      icon: 'ClipboardIcon',
      resource: 'tickets',
      action: 'read',
    },
    {
      title: 'Biletlerim',
      route: 'my-tickets',
      icon: 'MessageSquareIcon',
      resource: 'Dashboard',
      action: 'read',
    },
    {
      title: 'Yeni Bilet',
      route: 'create-ticket',
      icon: 'PlusCircleIcon',
      resource: 'Dashboard',
      action: 'read',
    },
  ]