<template>
  <b-card>
    <b-row v-if="loading">
      <b-col md="12" class="text-center">
        <b-spinner
          style="width:3rem; height:3rem;"
        />
      </b-col>
    </b-row>

    <b-row v-if="!loading">
      <b-col cols="12" class="text-center my-2">
        <h3>{{site_settings.site_company_name}}</h3>
        <p>{{site_settings.site_address_street}}, {{site_settings.site_address_postcode}} {{site_settings.site_address_city}}</p>
        <p>T: {{site_settings.site_phone}}</p>
        <p>E: {{site_settings.site_email}}</p>
        <p>W: {{site_settings.site_web_site}}</p>
        <p style="margin:unset">{{site_settings.site_address_work_hours}}</p>
      </b-col>
    </b-row>

    <b-row v-if="!loading && $can('update', 'site_settings')">
      <b-col md="4" cols="12" sm="6">
        <h5 class="mt-1">{{$t('Şirket Adı')}}</h5>
        <b-form-input v-model="site_settings.site_company_name" name="site_company_name" />
      </b-col>
      <b-col md="6" cols="12" sm="6">
        <h5 class="mt-1">{{$t('Sokak & No')}}</h5>
        <b-form-input v-model="site_settings.site_address_street" name="site_address_street" />
      </b-col>
      <b-col md="2" cols="12" sm="6">
        <h5 class="mt-1">{{$t('Posta Kodu')}}</h5>
        <b-form-input v-model="site_settings.site_address_postcode" name="site_address_postcode" />
      </b-col>
      <b-col sm="6" cols="12">
        <h5 class="mt-1">{{$t('Şehir')}}</h5>
        <b-form-input v-model="site_settings.site_address_city" name="site_address_city" />
      </b-col>
      <b-col sm="6" cols="12">
        <h5 class="mt-1">{{$t('Çalışma Saatleri')}}</h5>
        <b-form-input v-model="site_settings.site_address_work_hours" name="site_address_work_hours" />
      </b-col>
      <b-col cols="12" md="4" sm="6"> 
        <h5 class="mt-1">{{$t('Email')}}</h5>
        <b-form-input v-model="site_settings.site_email" name="site_email" />
      </b-col>
      <b-col cols="12" md="4" sm="6"> 
        <h5 class="mt-1">{{$t('Telefon')}}</h5>
        <b-form-input v-model="site_settings.site_phone" name="site_phone" />
      </b-col>
      <b-col cols="12" md="4" sm="6"> 
        <h5 class="mt-1">{{$t('Web Sitesi')}}</h5>
        <b-form-input v-model="site_settings.site_web_site" name="site_web_site" />
      </b-col>

      <b-col cols="12" class="text-center">
        <b-button v-if="$can('update', 'site_settings')" variant="primary" class="mt-2" @click="update">{{$t('Ayarları Güncelle')}}</b-button>
      </b-col>
    </b-row>
      
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BSpinner, BButton, BFormGroup, BFormInput} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSpinner,
    BButton, 
    BFormGroup, 
    BFormInput
  },
  directives:{
    Ripple,
  },
  data() {
    return {
      site_settings: null,
      loading: 1,
      navbarColors: ['', 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'],
    }
  },
  mounted() {

    this.$store.dispatch('appConfig/get_settings').then(response => {
      this.site_settings = response.data
      this.loading = 0
    });
  },

  methods:{
        async update(){
      if(!this.loading){
        this.loading = 1
        let formData = new FormData()
        formData.append('settings', JSON.stringify(this.site_settings))

        await axios.post('site-settings/update', formData)
          .then(response => {
            this.site_settings = response.data
              this.$store.dispatch('appConfig/set_settings').then(()=> {
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Başarıyla Güncellendi'),
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: `${this.$t('Ayarlar')} ${this.$t('Başarıyla Güncellendi')}`,
                },
              })
              this.loading = 0
              this.lightLogo = null
              this.darkLogo = null
            });
          })
          .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Birşeyler Ters Gitti'),
                  icon: 'XIcon',
                  variant: 'danger',
                  text: `${this.$t('Ayarlar')} ${this.$t('Güncellenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
                },
              })
              this.loading = 0
              this.lightLogo = null
              this.darkLogo = null
          })        
      }
    },
  }

}
</script>
