<template>
    <b-form @submit.prevent>
      <validation-observer
            ref="addForm"
            #default="{invalid}"
          >
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('Email')"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Email')"
              vid="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="item.email"
                :name="$t('Email')"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('Email')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label-for="password"
            :label="$t('Şifre')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Şifre')"
              vid="password"
              :rules="item.id ? '' : 'required'"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="item.password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  :name="$t('Şifre')"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            label-for="password_confirmation"
            :label="$t('Şifreyi Doğrula')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Şifreyi Doğrula')"
              vid="password_confirmation"
              :rules="item.password.length ? 'required|confirmed:password' : ''"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password_confirmation"
                  v-model="item.password_confirmation"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  :name="$t('Şifreyi Doğrula')"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

      <b-col cols="12">
            <b-form-group
            :label="$t('Rol')"
            label-for="role"
          >
            <b-form-select
              id="role"
              :options="role_options"
              v-model="item.role_id"
              v-if="!loading"
            />
            <b-spinner v-if="loading"/>
            </b-form-group>
      </b-col>
      <b-col cols="12">
          <b-form-group :label="$t('Cinsiyet')">
          <b-form-radio-group v-model="item.gender" :options="[
                                                          { value: 'male', text: $t('male') },
                                                          { value: 'female', text: $t('female') },
                                                          { value: 'neutral', text: $t('neutral') }
                                                        ]" name="gender" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
          <validation-provider
              #default="{ errors }"
              :name="$t('İsim')"
              vid="name"
              rules="required"
          >
            <b-form-group
            :label="$t('İsim')"
            label-for="name"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              id="name"
              :state="errors.length > 0 ? false:null"
              :placeholder="$t('İsim')"
              v-model="item.name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
      </b-col>

      <b-col cols="12">
          <validation-provider
              #default="{ errors }"
              :name="$t('Soyisim')"
              vid="surname"
              rules="required"
          >
            <b-form-group
            :label="$t('Soyisim')"
            label-for="surname"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              id="surname"
              :state="errors.length > 0 ? false:null"
              :placeholder="$t('Soyisim')"
              v-model="item.surname"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
      </b-col>

      <b-col cols="12">
          <validation-provider
              #default="{ errors }"
              :name="$t('Telefon')"
              vid="phone"
              rules="required"
          >
            <b-form-group
            :label="$t('Telefon')"
            label-for="phone"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              id="phone"
              :state="errors.length > 0 ? false:null"
              :placeholder="$t('Telefon')"
              v-model="item.phone"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
      </b-col>

      <b-col :cols="item.id ? '10' : '12'">
        <b-form-group
          :label="$t('Adres')"
          label-for="address"
        >
        <b-form-input
          id="address"
          :placeholder="$t('Adres')"
          v-model="item.address"
          :disabled="!!item.address_confirmed"
        />
        </b-form-group>
      </b-col>
      <b-col v-if="item.id" cols="2" class="mt-2">
          <b-button style="margin-top:3px" size="sm" v-b-tooltip.hover.top="item.address_confirmed ? $t('Kilidi Aç') : $t('Kilitle')" :variant="item.address_confirmed ? 'danger' : 'success'" @click="toggleLock(item)">
            <feather-icon
              :icon="item.address_confirmed ? 'UnlockIcon' : 'LockIcon'"
              size="18"
            />
        </b-button>
      </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click = "add"
            :disabled="invalid"
            v-if="!item.id"
          >
            {{$t('Ekle')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click = "update"
            :disabled="invalid"
            v-if="item.id"
          >
            {{$t('Kaydet')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="cancel"
            variant="outline-secondary"
          >
            {{$t('İptal')}}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
    </b-form>

</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadioGroup,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  BFormSelect,
  VBTooltip 
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import axios from '@axios'
 
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BSpinner,
    BFormSelect,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
      email,
      confirmed,
      item: this.user,
      roles: null,
      loading:1,
    };
  },
  created(){
    if(!this.item.password) this.item.password = ''
    if(!this.item.password_confirmation) this.item.password_confirmation = ''

  },
  mounted(){
    this.$store.dispatch('user/list_roles').then(response => {
      this.roles = response.data
      this.loading = 0
    });
  },
  mixins: [togglePasswordVisibility],
  computed:{
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    role_options(){
      if(this.roles){
        let options = []
        this.roles.forEach(item => options.push({value:item.id, text:item.name}))
        return options
      }
      return null
    }
  },

  props: ["user"],
  methods: {
    toggleLock(item){
      axios.get('users/toggle-confirm-address/' + item.id)
      item.address_confirmed = item.address_confirmed ? 0 : 1
    },
    add() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit('addNewItem', this.item)
        }
      })
    },
    update(){
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit('update', this.item)
        }
      })
    },
    cancel(){
      this.$emit('cancel')
    }
  },
};
</script>
