<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    ref="notifications"
  >
    <template #button-content>
      <feather-icon
        :badge="notifications ? notifications.length : 0"
        badge-classes="bg-primary"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{$t('Bildirimler')}}
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{notifications ? notifications.length : 0}} {{$t('Yeni')}}
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      v-if="notifications"
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications.filter(n => n.type != 'App\\Notifications\\DocumentCreated')"
        :key="notification.id"
        :to="'/tickets/details/'+ notification.data.ticket.id"
        @click="readNotification(notification.id)"
      >
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ getTitle(notification.type) }}
            </span>
          </p>
          <span class="notification-text">
            <b>#{{notification.data.ticket.id}} - {{notification.data.ticket.owner.full_name}} - {{notification.data.ticket.date_time}}</b> <br>
            {{ getContent(notification.type) }}
            </span>
        </b-media>
      </b-link>

      <b-link
        v-for="notification in notifications.filter(n => n.type == 'App\\Notifications\\DocumentCreated')"
        :key="notification.id"
        :to="{name:'documents'}"
        @click="readNotification(notification.id)"
      >
        <b-media>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ getTitle(notification.type) }}
            </span>
          </p>
          <span class="notification-text">
            <b>{{notification.data.document.name}} - {{notification.data.document.date_time}}</b> 
            </span>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="readAll"
      v-if="notifications.length"
    >{{$t('Hepsini Oku')}}</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import axios from "@axios";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,

  },
  directives: {
    Ripple,
  },
  data(){
    return{
      interval:null,
      notifications:[],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      }
    }
  },
  beforeCreate(){
    axios.get('notifications').then(response => this.notifications = response.data)
      let reachThis = this
      this.interval = setInterval(function(){
        axios.get('notifications').then(response => {
          reachThis.notifications = response.data
        })
      },10000)
  },
  beforeDestroy(){
    clearInterval(this.interval)
  },

  methods:{
    readAll(){
      this.notifications = []
      axios.get('notifications/read-all')

    },
    getTitle(type){
      switch(type){
        case "App\\Notifications\\TicketCreated":
          return this.$t('Ticket Oluşturuldu');
        case "App\\Notifications\\TicketReplied":
          return this.$t('Ticket Cevaplandı');
        case "App\\Notifications\\TicketClosed":
          return this.$t('Ticket Kapatıldı');
        case "App\\Notifications\\DocumentCreated":
          return this.$t('Döküman Oluşturuldu');
      }
    },

    getContent(type){
      switch(type){
        case "App\\Notifications\\TicketCreated":
          return this.$t('ticket_created_content');
        case "App\\Notifications\\TicketReplied":
          return this.$t('ticket_replied_content');
        case "App\\Notifications\\TicketClosed":
          return this.$t('ticket_closed_content');
        case "App\\Notifications\\DocumentCreated":
          return this.$t('document_created_content');
      }
    },

    readNotification(id){
        this.$refs.notifications.hide()
        axios.get('notifications/read/' +id).then(response => this.notifications = response.data)
    }
  }

}
</script>

<style>

</style>
