<template>
  <b-card>
    <!-- form -->
    <validation-observer
          ref="passwordForm"
          #default="{invalid}"
      >
    <b-form>
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            label-for="old_password"
            :label="$t('Eski Şifre')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Eski Şifre')"
              vid="old_password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="old_password"
                  v-model="oldPassword"
                  class="form-control-merge"
                  :type="passwordFieldTypeOld"
                  :state="errors.length > 0 ? false:null"
                  :name="$t('Eski Şifre')"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="password"
            :label="$t('Yeni Şifre')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Yeni Şifre')"
              vid="password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password"
                  v-model="newPassword"
                  class="form-control-merge"
                  :type="passwordFieldTypeNew"
                  :state="errors.length > 0 ? false:null"
                  :name="$t('Yeni Şifre')"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconNew"
                    class="cursor-pointer"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            label-for="password_confirmation"
            :label="$t('Şifreyi Doğrula')"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Şifreyi Doğrula')"
              vid="password_confirmation"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="password_confirmation"
                  v-model="confirmation"
                  class="form-control-merge"
                  :type="passwordFieldTypeRetype"
                  :state="errors.length > 0 ? false:null"
                  :name="$t('Şifreyi Doğrula')"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            :disabled="invalid"
            @click="$emit('update', oldPassword, newPassword, confirmation)"
          >
            {{$t('Şifreyi Değiştir')}}
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from '@validations'
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmation: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      required
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
