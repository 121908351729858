<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{$t('app_title')}} {{ new Date().getFullYear() }}. 
      <span class="d-inline-block"> {{$t('Powered by')}} <a href="https://peop.de">Peop Digital</a></span>
    </span>

  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
