export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },

  
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword-v2.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta:{
      resource: 'Dashboard',
      action: 'read',
    }
  },
  
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
    },
  },

]
