import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'

Vue.use(VueI18n)
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  let others
  (async () => {
    await store.dispatch('language/list').then(response => {
      others = response.data
      others.forEach(item => {
        if(item.en) messages['en'][item.key] = item.en
        if(item.de) messages['de'][item.key] = item.de
      })
    })
  })()
  return messages
}
export default new VueI18n({
  locale: store.state.appConfig.locale,
  fallbackLocale: 'de',
  messages: loadLocaleMessages(),
})
