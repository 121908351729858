var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":_vm.logo,"height":"45px","draggable":"false","to":{name: 'login'}}})]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",style:({
        backgroundImage: 'url(' + _vm.imgUrl + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }),attrs:{"lg":"8"}}),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('Şifreyi Sıfırla'))+" 🔒 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('reset_password_message'))+" ")]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Yeni Şifre'),"label-for":"reset-password-new"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new","type":_vm.password1FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-new","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password1ToggleIcon},on:{"click":_vm.togglePassword1Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"reset-password-confirm","label":_vm.$t('Şifreyi Doğrula')}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-confirm","type":_vm.password2FieldType,"state":errors.length > 0 ? false:null,"name":"reset-password-confirm","placeholder":"············"},model:{value:(_vm.cPassword),callback:function ($$v) {_vm.cPassword=$$v},expression:"cPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{staticClass:"auth-button",attrs:{"block":"","type":"submit","variant":"null","disabled":!!_vm.loading}},[_vm._v(" "+_vm._s(_vm.loading ? "Yükleniyor" : _vm.$t('Şifreyi Sıfırla'))+" ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t("Girişe Geri Dön"))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }