export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta:{
      resource: 'Dashboard',
      action: 'read',
    }
  },

]
