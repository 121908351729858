<template>
  <b-card no-body :class="hasBorder ? 'border border-primary bord' : '' ">
    <b-modal v-if="$can('updateReply', 'tickets') && message.ticket_id" 
      ref="reply-form"
      centered
      :title="$t('Yanıtı Güncelle')"
      hide-footer
      size="lg">
      <reply-form :item="message" @cancel="$refs['reply-form'].hide()" @replyUpdated="replyUpdated"/>
    </b-modal>
    <b-card-header
      v-if="message.owner"
      class="email-detail-head"
    >
      <div class=" d-flex justify-content-between align-items-center flex-wrap" >
        <b-avatar
          size="48"
          :text="avatarText(message.owner.full_name)"
          class="mr-75"
        />
      <div>
      {{message.owner.full_name}} <br>
      <small class="text-primary">{{message.owner.role.name}}</small>
      </div>

      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <a @click="deleteReply(message.id)" class="mr-1 text-danger" v-if="$can('deleteReply', 'tickets') && message.ticket_id">{{$t('Yanıtı Sil')}}</a> 
        <a @click="$refs['reply-form'].show()" class="mr-1" v-if="$can('updateReply', 'tickets') && message.ticket_id">{{$t('Yanıtı Güncelle')}}</a>
        <small class="mail-date-time text-secondary">{{ message.date_time}}</small>
      </div>
    </b-card-header>

    <b-card-body class="mail-message-wrapper pt-2">
      <!-- eslint-disable vue/no-v-html -->
      <div
        class="mail-message"
        v-html="message.message"
      />
      <!-- eslint-enable -->
    </b-card-body>

    <b-card-footer v-if="message.all_files && message.all_files.length">
      <div class="mail-attachments">
        <div class="d-flex align-items-center mb-1">
          <feather-icon
            icon="PaperclipIcon"
            size="16"
          />
          <h5 class="font-weight-bolder text-body mb-0 ml-50">
             {{$t('Dosyalar')}}
          </h5>
        </div>

        <div class="d-flex flex-column">
          <b-link
            v-for="(file, index) in message.all_files"
            :key="index"
            :href="file.file"
            target="_blank"
            :class="{'mt-75': index}"
          >
            <span class="text-muted font-weight-bolder align-text-top">{{  file.file.split("/").slice(-1)[0]  }}</span>
          </b-link>
        </div>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BDropdown, BDropdownItem, BCard, BCardHeader, BCardBody, BCardFooter, BAvatar, BLink, BImg, BModal
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import { avatarText } from '@core/utils/filter'
import ReplyForm from "./ReplyForm.vue";
import axios from "@axios";

export default {
  components: {
    BDropdown, BDropdownItem, BCard, BCardHeader, BCardBody, BCardFooter, BAvatar, BLink, BImg, BModal, ReplyForm
  },
  props: ['message', 'hasBorder'],
  
  setup() {
    return {
      formatDate,
      avatarText,
    }
  },

  methods:{
    deleteReply(id){
      this.$swal.fire({
        title: this.$t("Emin misiniz?"),
        text: this.$t("reply_delete_question"),
        icon: "question",
        backdrop: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: true,
        confirmButtonText: this.$t("Evet"),
        denyButtonText: this.$t("Hayır"),
      }).then(response => {
        if(response.isConfirmed){
          axios.delete('tickets/delete-reply/'+this.message.ticket_id + '/' + id).then(() => {
            this.$emit('replyDeleted', this.message)
          })
        }
      })
    },

    replyUpdated(reply){
      this.message = reply
      this.$emit('replyUpdated', reply);
      this.$refs['reply-form'].hide();
    }
  }

}
</script>

<style>

</style>
