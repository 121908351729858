import axios from '@axios'

export default {
  namespaced: true,
  state: {

    data: JSON.parse(localStorage.getItem('userData')),
    permissions: JSON.parse(localStorage.getItem('userPermissions'))
  },
  getters: {},
  mutations: {
    setPermissions(state, data){
        localStorage.setItem('userPermissions', JSON.stringify(data))
        state.permissions = JSON.parse(localStorage.getItem('userData'))
    },
    setData(state, data){
        localStorage.setItem('userData', JSON.stringify(data))
        state.data = JSON.parse(localStorage.getItem('userData'))
    },
  },
  actions: {
    login(mutations, data){
        mutations.commit('setPermissions', data.permissions)
        mutations.commit('setData', data.data)
    },
    list_users() {
      return new Promise((resolve, reject) => {
        axios
          .get('/users')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    list_roles() {
      return new Promise((resolve, reject) => {
        axios
          .get('/roles')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}