<template>
    <b-card title="">
    <b-row v-if="loading">
      <b-col md="12" class="text-center">
        <b-spinner style="width: 3rem; height: 3rem" />
      </b-col>
    </b-row>
    <b-form @submit.prevent v-if="!loading">
      <validation-observer ref="addForm" #default="{ invalid }">
        <h1 class="mb-2">{{$t('Yeni Bilet')}}</h1>
        <b-row v-if="!item.department_id">
          <b-col cols="12"
            ><h3>{{ $t("Departman Seç") }}</h3></b-col
          >

          <br />
          <b-col
            cols="6"
            xl="2"
            lg="3"
            md="4"
            sm="4"
            class="text-center mt-2"
            v-for="department in departments"
            :key="department.id"
          >
            <h5 style="height: 30px">{{ department.name }}</h5>
            <img
              :src="getLogo(department)"
              class="w-100 cursor-pointer border p-1"
              style="
                height: 150px;
                width: 150px !important;
                display: block;
                margin: 0 auto;
              "
              @click="item.department_id = department.id"
            />
            <b-button
              @click="item.department_id = department.id"
              variant="primary"
              class="mt-2"
              >{{ $t("Seç") }}</b-button
            >
          </b-col>
        </b-row>
        <b-row v-if="item.department_id">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('Konu')"
              vid="subject"
              rules="required"
            >
              <b-form-group
                :label="$t('Konu')"
                label-size="lg"
                label-for="subject"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="subject"
                  :placeholder="$t('Konu')"
                  v-model="item.subject"
                  :name="$t('Konu')"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12"  sm="6">
            <b-form-group label-size="lg" :label="$t('Departman')" label-for="department_id">
              <b-form-select
                id="department_id"
                :options="department_options"
                v-model="item.department_id"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12"  sm="6">
            <b-form-group label-size="lg" :label="$t('Öncelik')" label-for="priority">
              <b-form-select
                id="priority"
                :options="[
                  { value: 0, text: $t('Düşük') },
                  { value: 1, text: $t('Orta') },
                  { value: 2, text: $t('Yüksek') },
                  { value: 3, text: $t('Çok Yüksek') },
                ]"
                v-model="item.priority"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('Mesaj')"
              vid="message"
              rules="required"
            >
              <b-form-group
                :label="$t('Mesaj')"
                label-size="lg"
                label-for="message"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-textarea
                  id="message"
                  :placeholder="$t('Mesaj')"
                  v-model="item.message"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" class="my-2">
            <b-form-group label-size="lg" :label="$t('Dosyalar')" label-for="files">
              <b-form-file
                class="mt-1"
                id="files"
                accept=".jpg, .png, .jpeg, .pdf, .doc, .docx, .webp, .gif"
                multiple
                v-model="item.files"
                :browse-text="$t('browse')"
                :placeholder="$t('no_file_chosen')"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col offset-md="4" class="mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="add"
              :disabled="invalid"
              v-if="!item.id"
            >
              {{ $t("Gönder") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              @click="cancel"
              variant="outline-secondary"
            >
              {{ $t("İptal") }}
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BFormTextarea,
  BForm,
  BButton,
  BSpinner,
  BModal,
  BCard
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard
  },
  directives: {
    Ripple,
  },
  beforeCreate() {
    this.$store.dispatch("departments/list").then((response) => {
      this.departments = response.data;
      this.loading = 0;
    });
  },
  data() {
    return {
      required,
      loading: 0,
      departments: null,
      item: {
        department_id: null,
        subject: null,
        priority: 0,
        files: [],
        message: "",
      },
    };
  },
  computed: {
    department_options() {
      if (this.departments) {
        let options = [];
        this.departments.forEach((item) =>
          options.push({ value: item.id, text: item.name })
        );
        return options;
      }
      return null;
    },
  },
  methods: {
    getLogo(department) {
      return axios.defaults.baseURL + "/" + department.logo;
    },
    async add() {
      await this.$refs.addForm.validate().then(async (success) => {
        if (success) {
          if (!this.loading) {
            let item = this.item
            this.loading = 1;
            let formData = new FormData();
            formData.append("subject", item.subject);
            formData.append("message", item.message);
            formData.append("priority", item.priority);
            formData.append("department_id", item.department_id);
            formData.append("status", 0);
            if (item.files) {
              item.files.forEach((file) => formData.append("files[]", file));
            }
            await axios
              .post("tickets/create", formData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Başarıyla Eklendi"),
                    icon: "CheckIcon",
                    variant: "success",
                    text: `${this.$t("Yeni Bilet")} ${
                      response.data.name
                    } ${this.$t("Başarıyla Eklendi")}`,
                  },
                });
                this.$router.push({name: 'my-tickets'});
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "bottom-right",
                  props: {
                    title: this.$t("Birşeyler Ters Gitti"),
                    icon: "XIcon",
                    variant: "danger",
                    text: `${this.$t("Yeni Bilet")} ${this.$t(
                      "Eklenirken Bir Hata Oluştu:"
                    )} ${error.response.data.errors}`,
                  },
                });
                this.loading = 0;
              });
          }
        }
      });
    },
    cancel() {
        this.$router.push({name: 'my-tickets'});
    },
  },
};
</script>
