<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo" :to="{name: 'login'}">
        <img :src="logo" height="45px" draggable="false">
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5" :style="{backgroundImage: 'url('+imgUrl+')', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',  backgroundPosition: 'center'}"
      >

      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{$t('Kayıt Ol')}}
          </b-card-title>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <b-form-group :label="$t('Cinsiyet')">
                <b-form-radio-group v-model="gender" :options="[
                                                                { value: 'male', text: $t('male') },
                                                                { value: 'female', text: $t('female') },
                                                                { value: 'neutral', text: $t('neutral') }
                                                              ]" name="gender" />
              </b-form-group>
              <b-form-group
                :label="$t('İsim')"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('İsim')"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="register-name"
                    v-model="name"
                    :name="$t('İsim')"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="$t('Soyisim')"
                label-for="register-surname"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Soyisim')"
                  vid="surname"
                  rules="required"
                >
                  <b-form-input
                    id="register-surname"
                    v-model="surname"
                    :name="$t('Soyisim')"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Address
              <b-form-group
                :label="$t('Sokak & No')"
                label-for="register-street"
              >
                  <b-form-input
                    id="register-street"
                    v-model="street"
                    :name="$t('Sokak & No')"
                  />
              </b-form-group>
              <b-form-group
                :label="$t('Posta Kodu')"
                label-for="register-postcode"
              >
                  <b-form-input
                    id="register-postcode"
                    v-model="postcode"
                    :name="$t('Posta Kodu')"
                  />
              </b-form-group>
              <b-form-group
                :label="$t('Şehir')"
                label-for="register-city"
              >
                  <b-form-input
                    id="register-city"
                    v-model="city"
                    :name="$t('Şehir')"
                  />
              </b-form-group>
              -->
              <b-form-group
                :label="$t('Kayıt Kodu')"
                label-for="register-code"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Kayıt Kodu')"
                  vid="code"
                  rules="required"
                >
                  <b-form-input
                    id="register-code"
                    v-model="code"
                    :name="$t('Kayıt Kodu')"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                :label="$t('Email')"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Email')"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="u_email"
                    :name="$t('Email')"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="password"
                :label="$t('Şifre')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Şifre')"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      :name="$t('Şifre')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label-for="password_confirmation"
                :label="$t('Şifreyi Doğrula')"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Şifreyi Doğrula')"
                  vid="password_confirmation"
                  rules="required|confirmed:password"

                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password_confirmation"
                      v-model="password_confirmation"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      :name="$t('Şifreyi Doğrula')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Okudum Kabul Ediyorum')"
                  vid="register_agree"
                  rules="required:true"
                >

                <b-form-checkbox
                  :name="$t('Okudum Kabul Ediyorum')"
                  id="register_agree"
                  v-model="agree"
                  required
                >
                  {{$t('Okudum Kabul Ediyorum')}} <a target="_blank" href="https://hocos.com/agb">{{$t('register_agree_file_1_name')}}</a>, <a target="_blank" href="https://hocos.com/datenchutz">{{$t('register_agree_file_2_name')}}</a>
                  <!--<a target="_blank" href="./files/register_agree_file_1.pdf">{{$t('register_agree_file_1_name')}}</a>, <a target="_blank" href="./files/register_agree_file_2.pdf">{{$t('register_agree_file_2_name')}}</a>-->
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                variant="null"
                class="auth-button"
                block
                type="submit"
                v-if="!loading"
                :disabled="invalid"
              >
                {{$t('Kayıt Ol')}}
              </b-button>
              <b-button
                variant="null"
                class="auth-button"
                disabled
                block
                v-if="loading"
              >
                <b-spinner
                  small
                />
                {{$t('Yükleniyor')}}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{$t('Zaten hesabınız var mı?')}}</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;{{$t('Giriş Yap')}}</span>
            </b-link>
          </p>

        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BSpinner, BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BFormRadioGroup
} from 'bootstrap-vue'
import { required, email, confirmed } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BSpinner,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormRadioGroup,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      name: '',
      surname: '',
      code: '',
      u_email: '',
      password: '',
      password_confirmation: '',
      gender:'male',
      city:'',
      street:'',
      postcode:'',
      loading: 0,
      agree: false,
      sideImg: require('@/assets/images/pages/hocos-bg.png'),
      logo: require('@/assets/images/logo/logo.png'),
      // validation
      required,
      email,
      confirmed
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/hocos-bg.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.loading = 1;
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register({
            gender: this.gender,
            name: this.name,
            surname: this.surname,
            address: this.street + ' ' + this.postcode + ' ' + this.city,
            code: this.code,
            email: this.u_email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            locale: this.$i18n.locale
          })
            .then((response) => {
              
              this.$store.dispatch('user/login', {data: response.data.userData, permissions: response.data.userPermissions})
              this.$ability.update(response.data.userPermissions)
              this.$router.replace('/')
            })
            .catch(error => {
              this.loading = 0;
              let errors = error.response.data.errors
              for(let key in errors){
                errors[key] = this.$t(errors[key])
              }
              if(error.response.status == 422){
                  this.$refs.registerForm.setErrors(errors)
              }else{
                console.log(error.response)
              }
              
            })
        }else{
          this.loading = 0;
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
