<template>
  <b-card title="">
    <b-row v-if="loading">
      <b-col md="12" class="text-center">
        <b-spinner
          style="width:3rem; height:3rem;"
        />
      </b-col>
    </b-row>

    <b-row v-if="!loading">
      <b-col cols="8">
        <h5 class="mt-1">{{$t('Açık Tema Logo')}}</h5>
          <b-form-file
            class="mt-1"
            id="light_logo"
            accept=".jpg, .png, .jpeg"
            v-model="lightLogo"
            @change="onLightLogoChange" 
            :browse-text="$t('browse')"
            :placeholder="$t('no_file_chosen')"
          />
      </b-col>
      <b-col cols="4">
        <h5 class="mt-1">{{$t('Önizleme')}}</h5>
        <img class="w-100" v-if="site_settings.light_logo" :src="preview_light">
      </b-col>
      <b-col cols="8">
        <h5 class="mt-1">{{$t('Koyu Tema Logo')}}</h5>
          <b-form-file
            class="mt-1"
            id="dark_logo"
            accept=".jpg, .png, .jpeg"
            v-model="darkLogo"
            @change="onDarkLogoChange"
            :browse-text="$t('browse')"
            :placeholder="$t('no_file_chosen')"
          />
      </b-col>
      <b-col cols="4">
        <h5 class="mt-1">{{$t('Önizleme')}}</h5>
        <img class="w-100" v-if="site_settings.dark_logo" :src="preview_dark">
      </b-col>
      <b-col cols="12">
        <b-form-group>
          <h5 class="mt-1">{{$t('Sol Menü Görüntülenmesi')}}</h5>
          <b-form-radio-group
            v-model="site_settings.menu_collapsed"
            :options="[{value:'true', text: $t('Dar Menü')}, {value:'false', text: $t('Geniş Menü')}]"
            name="menu-collapsed"
            class="mt-1"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group>
          <h5 class="mt-1">{{$t('Üst Menü Görüntülenmesi')}}</h5>
          <b-form-radio-group
            v-model="site_settings.navbar_type"
            :options="[{value:'floating', text: $t('Boşluklu Sabit')}, {value:'sticky', text: $t('Boşluksuz Sabit')}, {value:'static', text:$t('En Üstte')}]"
            name="navbar-type"
            class="mt-1"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <h5 class="mt-1 mb-1">{{$t('Üst Menü Arkaplan Rengi')}}</h5>
            <div
            v-for="(color, index) in navbarColors"
            :key="color"
            class="d-inline-block rounded mr-1 cursor-pointer"
            :class="[`bg-${color}`, {'border border-light': !index}, {'mark-active': site_settings.navbar_color === color}]"
            @click="site_settings.navbar_color = color"
            style="padding:10px"
          />
      </b-col>
      <b-col cols="12">
        <b-form-group>
          <h5 class="mt-1">{{$t('Footer Görüntülenmesi')}}</h5>
          <b-form-radio-group
            v-model="site_settings.footer_type"
            :options="[{value:'sticky', text:$t('Sabit')}, {value:'static', text:$t('En Altta')}]"
            name="footer-type"
            class="mt-1"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group>
          <h5 class="mt-1">{{$t('Sayfa Değiştirme Animasyonu')}}</h5>
          <b-form-radio-group
            v-model="site_settings.router_transition"
            :options="[{value:'zoom-fade', text:$t('Zoom Fade')},
                       {value:'slide-fade', text:$t('Slide Fade')},
                       {value:'fade-bottom', text:$t('Fade Bottom')},
                       {value:'fade', text:$t('Fade')},
                       {value:'zoom-out', text:$t('Zoom Out')},
                       {value:'none', text:$t('Animasyon Yok')},]"
            name="router-transition"
            class="mt-1"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" class="border-top border-bottom mt-1 mb-1"></b-col>
      <b-col cols="12" class="border-top border-bottom mt-1 mb-1"></b-col>
      <b-col cols="12" md="4" sm="6">
        <h5 class="mt-1">{{$t('Mail Host')}}</h5>
        <b-form-input v-model="site_settings.mail_host" name="mail_host" />
      </b-col>
      <b-col cols="12" md="4" sm="6" >
        <h5 class="mt-1">{{$t('Mail Mailer')}}</h5>
        <b-form-input v-model="site_settings.mail_mailer" name="mail_mailer" />
      </b-col>
      <b-col cols="12" md="4" sm="6">
        <h5 class="mt-1">{{$t('Mail Port')}}</h5>
        <b-form-input v-model="site_settings.mail_port" type="number" name="mail_port" />
      </b-col>
      <b-col sm="6" cols="12">
        <h5 class="mt-1">{{$t('Mail Username')}}</h5>
        <b-form-input v-model="site_settings.mail_username" name="mail_username" />
      </b-col>
      <b-col sm="6" cols="12">
        <h5 class="mt-1">{{$t('Mail Password')}}</h5>
        <b-form-input v-model="site_settings.mail_password" name="mail_password" />
      </b-col>
      <b-col sm="6" cols="12">
        <h5 class="mt-1">{{$t('Mail Gönderen Adres')}}</h5>
        <b-form-input v-model="site_settings.mail_from_address" name="mail_from_address" />
      </b-col>
      <b-col sm="6" cols="12">
        <h5 class="mt-1">{{$t('Mail Gönderen Adı')}}</h5>
        <b-form-input v-model="site_settings.mail_from_name" name="mail_from_name" />
      </b-col>
      <!--
      <b-col cols="12" class="border-top border-bottom mt-1 mb-1"></b-col>
      <b-col cols="12" class="border-top border-bottom mt-1 mb-1"></b-col>
      <b-col md="4" cols="12" sm="6">
        <h5 class="mt-1">{{$t('Şirket Adı')}}</h5>
        <b-form-input v-model="site_settings.site_company_name" name="site_company_name" />
      </b-col>
      <b-col md="6" cols="12" sm="6">
        <h5 class="mt-1">{{$t('Sokak & No')}}</h5>
        <b-form-input v-model="site_settings.site_address_street" name="site_address_street" />
      </b-col>
      <b-col md="2" cols="12" sm="6">
        <h5 class="mt-1">{{$t('Posta Kodu')}}</h5>
        <b-form-input v-model="site_settings.site_address_postcode" name="site_address_postcode" />
      </b-col>
      <b-col sm="6" cols="12">
        <h5 class="mt-1">{{$t('Şehir')}}</h5>
        <b-form-input v-model="site_settings.site_address_city" name="site_address_city" />
      </b-col>
      <b-col sm="6" cols="12">
        <h5 class="mt-1">{{$t('Çalışma Saatleri')}}</h5>
        <b-form-input v-model="site_settings.site_address_work_hours" name="site_address_work_hours" />
      </b-col>
      <b-col cols="12" md="4" sm="6"> 
        <h5 class="mt-1">{{$t('Email')}}</h5>
        <b-form-input v-model="site_settings.site_email" name="site_email" />
      </b-col>
      <b-col cols="12" md="4" sm="6"> 
        <h5 class="mt-1">{{$t('Telefon')}}</h5>
        <b-form-input v-model="site_settings.site_phone" name="site_phone" />
      </b-col>
      <b-col cols="12" md="4" sm="6"> 
        <h5 class="mt-1">{{$t('Web Sitesi')}}</h5>
        <b-form-input v-model="site_settings.site_web_site" name="site_web_site" />
      </b-col>
      -->
      <b-col cols="12" class="text-center">
        <b-button v-if="$can('update', 'site_settings')" variant="primary" class="mt-2" @click="update">{{$t('Ayarları Güncelle')}}</b-button>
      </b-col>
    </b-row>
      
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BSpinner, BButton, BFormGroup, BFormRadioGroup, BFormRadio, BFormFile, BFormInput} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSpinner,
    BButton,
    BFormGroup, 
    BFormRadioGroup, 
    BFormRadio,
    BFormFile,
    BFormInput
  },
  directives:{
    Ripple,
  },
  data() {
    return {
      site_settings: null,
      loading: 1,
      navbarColors: ['', 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'dark'],
      darkLogo:null,
      lightLogo:null,
    }
  },
  mounted() {

    this.$store.dispatch('appConfig/get_settings').then(response => {
      this.site_settings = response.data
      this.loading = 0
    });
  },
  computed:{
    preview_light(){
      return this.lightLogo ? this.site_settings.light_logo : axios.defaults.baseURL + '/' + this.site_settings.light_logo
    },
    preview_dark(){
      return this.darkLogo ? this.site_settings.dark_logo : axios.defaults.baseURL + '/' + this.site_settings.dark_logo
    }
  },
  methods:{
    onLightLogoChange(e){
      const file = e.target.files[0];
      this.site_settings.light_logo = URL.createObjectURL(file);
    },
    onDarkLogoChange(e){
      const file = e.target.files[0];
      this.site_settings.dark_logo = URL.createObjectURL(file);
    },
    async update(){
      if(!this.loading){
        this.loading = 1
        let formData = new FormData()
        formData.append('settings', JSON.stringify(this.site_settings))
        if(this.darkLogo){
          formData.append('dark_logo', this.darkLogo)
        }
        if(this.lightLogo){
          formData.append('light_logo', this.lightLogo)
        }

        await axios.post('site-settings/update', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            this.site_settings = response.data
              this.$store.dispatch('appConfig/set_settings').then(()=> {
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Başarıyla Güncellendi'),
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: `${this.$t('Ayarlar')} ${this.$t('Başarıyla Güncellendi')}`,
                },
              })
              this.loading = 0
              this.lightLogo = null
              this.darkLogo = null
            });
          })
          .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Birşeyler Ters Gitti'),
                  icon: 'XIcon',
                  variant: 'danger',
                  text: `${this.$t('Ayarlar')} ${this.$t('Güncellenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
                },
              })
              this.loading = 0
              this.lightLogo = null
              this.darkLogo = null
          })        
      }
    },

  },
}
</script>
<style>
.mark-active {
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}
</style>