<template>
  <b-card title="">
    <b-row v-if="loading">
      <b-col md="12" class="text-center">
        <b-spinner
          style="width:3rem; height:3rem;"
        />
      </b-col>

    </b-row>
    <div class="w-100 text-right" v-if="!loading">

      <b-modal
        id="type-form-modal"
        centered
        :title="modalTitle"
        hide-footer
        ref="form-modal"
        size="lg"
        no-close-on-backdrop
        v-if="$can('create', 'departments') || $can('update', 'departments')"
      >
      <department-form @addNewItem="addNewItem" @update="update" @cancel="$refs['form-modal'].hide()" :item="updateItem"></department-form>
      </b-modal>
      <portal to="breadcrumb-right">
        <b-button
        @click = "newItem"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.type-form-modal
        variant="primary"
        v-if="$can('create', 'departments')"
        
      >{{$t('Yeni Departman Ekle')}}</b-button>
      </portal>
      
    </div>
    <b-table v-if="!loading" stacked :items="items" :fields="fields" class="d-block d-lg-none" tbody-tr-class="border-bottom-primary border-top-primary">
      <template #cell(actions)="row">
        <b-button class="mr-1 sbtn"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'departments')"
                  v-b-modal.type-form-modal>
          {{$t('Güncelle')}}
        </b-button>
        <b-button class="sbtn" variant="danger" @click="deleteItem(row.item.id)" v-if="$can('delete', 'departments')">
          {{$t('Sil')}}
        </b-button>
      </template>
      <template #cell(logo)="row">
        <img width="75" height="75" :src="getLogo(row.item.logo)">
      </template>
    </b-table>
    <b-table v-if="!loading" responsive :items="items" :fields="fields" class="d-none d-lg-block">
      <template #cell(actions)="row">
        <b-button class="mr-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'departments')"
                  v-b-modal.type-form-modal>
          {{$t('Güncelle')}}
        </b-button>
        <b-button variant="danger" @click="deleteItem(row.item.id)" v-if="$can('delete', 'departments')">
          {{$t('Sil')}}
        </b-button>
      </template>
      <template #cell(logo)="row">
        <img width="75" height="75" :src="getLogo(row.item.logo)">
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BRow, BCol, BTable, BCard, BSpinner, BButton, BModal, VBModal  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DepartmentForm from './DepartmentForm.vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BSpinner,
    BButton,
    BModal,
    DepartmentForm,

  },
  directives:{
    'b-modal' : VBModal,
    Ripple,
  },
  data() {
    return {
      items: null,
      loading: 1,
      updateItem:null,
      modalTitle: "",
      fields:[
        {key: 'id', label: 'ID', sortable:true},
        {key: 'logo', label: this.$t('Logo')},
        {key: 'name', label: this.$t('Departman Adı'), sortable:true},
        {key: 'actions', label: this.$t('İşlemler')},
      ]
    }
  },
  mounted() {

    this.$store.dispatch('departments/list').then(response => {
      this.items = response.data
      this.loading = 0
    });
  },

  methods:{
    getLogo(path){
      return axios.defaults.baseURL + '/' + path
    },
    newItem(){
      this.updateItem = {name: '', logo: null}
      this.modalTitle = this.$t("Yeni Departman")
    },
    updateModal(item){
      this.updateItem = Object.assign({},item)
      this.modalTitle = this.$t("Departmanı Güncelle")
    },
    async addNewItem(item, logo){
      if(!this.loading){
        this.loading = 1
        this.$refs['form-modal'].hide()
        this.loading = 1
        let formData = new FormData()
        formData.append('name', item.name)
        if(logo){
          formData.append('logo', logo)
        }
        await axios.post('departments/create', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            this.items.push(response.data)
            
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Eklendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Yeni Departman')} ${response.data.name} ${this.$t('Başarıyla Eklendi')}`,
              },
            })
            this.loading = 0
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Birşeyler Ters Gitti'),
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$t('Yeni Departman')} ${this.$t('Eklenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
              },
            })
            this.loading = 0
          })
      }
    },
    async update(item, logo){
      if(!this.loading){
        this.loading = 1
        this.$refs['form-modal'].hide()
        let formData = new FormData()
        formData.append('name', item.name)
        formData.append('department_id', item.id)
        if(logo){
          formData.append('logo', logo)
        }
        await axios.post('departments/update', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then((response) => {
            let i = this.items.findIndex(itm => itm.id == item.id)
            this.items[i] = response.data
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Güncellendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Departman')} ${this.$t('Başarıyla Güncellendi')}`,
              },
            })
            this.loading = 0
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Birşeyler Ters Gitti'),
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$t('Departman')} ${this.$t('Güncellenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
              },
            })
            this.loading = 0
          })
      }
    },
    deleteItem(id) {

      this.$swal.fire({
        title: this.$t("Emin misiniz?"),
        text: this.$t("department_delete_question"),
        icon: "question",
        backdrop: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: true,
        confirmButtonText: this.$t("Evet"),
        denyButtonText: this.$t("Hayır"),
      }).then(response => {
        if(response.isConfirmed){
          axios.delete('departments/delete/'+id).then(() => {
            this.items = this.items.filter(item => item.id != id)
          })
        }
      })
      
      
    },
  },
}
</script>
