import Vue from 'vue'
import Vuex from 'vuex'

// Modules

import app from './app'
import user from './user'
import departments from './departments'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import tickets from './tickets'
import language from './language'
import registerCodes from './register-codes'
import announcements from './announcements'
import documents from './documents'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    app,
    appConfig,
    verticalMenu,
    departments,
    tickets,
    language,
    registerCodes,
    announcements,
    documents
  },
  strict: process.env.DEV,
})
