export default [
    {
      header: 'Genel Ayarlar',
      resource: 'setting_title',
      action: 'read',
    },
    {
      title: 'Departmanlar',
      route: 'departments',
      icon: 'BriefcaseIcon',
      resource: 'departments',
      action: 'read',
    },
    {
      title: 'Kategoriler',
      route: 'document-categories',
      icon: 'AlignJustifyIcon',
      resource: 'document_categories',
      action: 'read',
    },
    {
      title: 'Duyurular',
      route: 'announcements',
      icon: 'BellIcon',
      resource: 'announcements',
      action: 'read',
    },
    {
      title: 'Dökümanlar',
      route: 'documents',
      icon: 'FileIcon',
      resource: 'documents',
      action: 'read',
    },
    {
      title: 'Kullanıcılar',
      route: 'users',
      icon: 'UsersIcon',
      resource: 'users',
      action: 'read',
    },
    {
      title: 'Roller',
      route: 'roles',
      icon: 'KeyIcon',
      resource: 'roles',
      action: 'read',
    },
    {
      title: 'Kayıt Kodları',
      route: 'register-codes',
      icon: 'CodeIcon',
      resource: 'register_codes',
      action: 'read',
    },
    {
      title: 'Site Ayarları',
      route: 'site-settings',
      icon: 'SettingsIcon',
      resource: 'site_settings',
      action: 'read',
    },

    {
      title: 'Çeviriler',
      route: 'translates',
      icon: 'GlobeIcon',
      resource: 'language',
      action: 'read',
    },
    {
      title: 'İletişim',
      route: 'contact',
      icon: 'InfoIcon',
      resource: 'Dashboard',
      action: 'read',
    },

  ]