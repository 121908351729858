<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">
        {{$t('Email')}}
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <span>{{$t('Mail Dili')}}</span>
        <b-form-radio-group
          id="accountSwitch0"
          class="mt-1"
          v-model="settings.email_language_en"
          name="check-button"
          :options="[{text: $t('Almanca'), value:false}, {text: $t('İngilizce'), value:true}]"
          inline
        >
        </b-form-radio-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch1"
          v-model="settings.mail_on_ticket_created"
          name="check-button"
          switch
          inline
        >
          <span>{{$t('Ticket oluştuğunda mail gönder')}}</span>
        </b-form-checkbox>
      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch2"
          v-model="settings.mail_on_ticket_replied"
          name="check-button"
          switch
          inline
        >
          <span>{{$t('Ticket cevaplandığında mail gönder')}}</span>
        </b-form-checkbox>
      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch3"
          v-model="settings.mail_on_ticket_closed"
          name="check-button"
          switch
          inline
        >
          <span>{{$t('Ticket kapandığında mail gönder')}}</span>
        </b-form-checkbox>
      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch7"
          v-model="settings.mail_on_document_created"
          name="check-button"
          switch
          inline
        >
          <span>{{$t('Döküman oluşturulduğunda mail gönder')}}</span>
        </b-form-checkbox>
      </b-col>

      <h6 class="section-label mx-1 mb-2">
        {{$t('Bildirim')}}
      </h6>
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch4"
          v-model="settings.notify_on_ticket_created"
          name="check-button"
          switch
          inline
        >
          <span>{{$t('Ticket oluştuğunda bildirim gönder')}}</span>
        </b-form-checkbox>
      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch5"
          v-model="settings.notify_on_ticket_replied"
          name="check-button"
          switch
          inline
        >
          <span>{{$t('Ticket cevaplandığında bildirim gönder')}}</span>
        </b-form-checkbox>
      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch6"
          v-model="settings.notify_on_ticket_closed"
          name="check-button"
          switch
          inline
        >
          <span>{{$t('Ticket kapandığında bildirim gönder')}}</span>
        </b-form-checkbox>
      </b-col>

      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-checkbox
          id="accountSwitch8"
          v-model="settings.notify_on_document_created"
          name="check-button"
          switch
          inline
        >
          <span>{{$t('Döküman oluşturulduğunda bildirim gönder')}}</span>
        </b-form-checkbox>
      </b-col>

      <!-- buttons -->
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1 mt-1"
          @click="$emit('update', settings)"
        >
          {{$t('Kaydet')}}
        </b-button>
      </b-col>
      <!--/ buttons -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox, BFormRadioGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
    BFormRadioGroup
  },
  directives: {
    Ripple,
  },
  props: ['settings'],
}
</script>
