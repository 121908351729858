<template>
  <b-card title="">
    <div id="printSection"></div>
    <b-row v-if="loading">
      <b-col md="12" class="text-center">
        <b-spinner
          style="width:3rem; height:3rem;"
        />
      </b-col>

    </b-row>
      <b-modal
        id="type-form-modal"
        centered
        :title="modalTitle"
        hide-footer
        ref="form-modal"
        size="lg"
        no-close-on-backdrop
        v-if="$can('update', 'users') || $can('create', 'users')"
      >
      <user-form @addNewItem="addNewItem" @update="update" @cancel="$refs['form-modal'].hide()" ref="user-form" :user="updateItem"></user-form>
      </b-modal>
      <portal to="breadcrumb-right">
        <b-button
        @click = "newItem"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.type-form-modal
        variant="primary"
        v-if="!loading && $can('create', 'users')"
      >{{$t('Yeni Kullanıcı Ekle')}}</b-button>
      </portal>
      
    <b-row v-if="!loading">
      <b-col md="5" cols="12" class="mt-2 mb-2">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            :placeholder="$t('Ara')"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">{{$t('Temizle')}}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      
      <b-col md="4" cols="12" class="mt-2 mb-2 d-none d-md-block">
        <v-select
            v-model="selected"
            multiple
            label="title"
            :placeholder="$t('Sütuna Göre Arayın')"
            :options="options"
          >
          <template v-slot:no-options>
            <div>{{ $t("nothing_found") }}</div>
          </template>
        </v-select> 
      </b-col>
      <b-col md="3" cols="12" class="mt-2 mb-2 text-center text-md-right">
      <vue-json-excel style="display:inline-block" :data="filteredItems" :fields="xlsFields" :name="$t('Kullanıcılar') +'.xls'">
        <b-button class="mr-1" size="sm" variant="outline-primary" v-b-tooltip.hover.top="$t('excell')">
          <img :src="xlsIcon" style="width:18px" class="cursor-pointer">
        </b-button>
      </vue-json-excel>
      <b-button variant="outline-info" size="sm" v-b-tooltip.hover.top="$t('print')" @click="print">
            <feather-icon
              icon="PrinterIcon"
              size="18"
            />
      </b-button>
      </b-col>
    </b-row>
        <b-table 
        v-if="!loading"
        stacked
        :items="items" 
        :fields="fields"
        :per-page="perPage" 
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        ref="usersTable"
        sticky-header
        @filtered="onFiltered"
        tbody-tr-class="border-bottom-primary border-top-primary"
        class="d-block d-lg-none">
      <template #cell(role_id)="row"
      >
      {{row.item.role.name}}
      </template>
      <template #cell(gender)="row"
      >
      {{$t(row.item.gender)}}
      </template>
      <template #cell(actions)="row">
        <b-button class="mr-1 sbtn"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'users')"
                  v-b-modal.type-form-modal>
          {{$t('Güncelle')}}
        </b-button>
        <b-button class="sbtn" variant="danger" @click="deleteItem(row.item.id)" v-if="$can('delete', 'users')">
          {{$t('Sil')}}
        </b-button>
      </template>

    </b-table>
    <b-table 
        v-if="!loading"
        responsive
        :items="items" 
        :fields="fields"
        :per-page="perPage" 
        :current-page="currentPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        ref="usersTable"
        sticky-header
        @filtered="onFiltered"
        class="d-none d-lg-block">
      <template #cell(role_id)="row"
      >
      {{row.item.role.name}}
      </template>
      <template #cell(gender)="row"
      >
      {{$t(row.item.gender)}}
      </template>
      <template #cell(actions)="row">
        <b-button class="mr-1 mb-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'users')"
                  v-b-modal.type-form-modal>
          {{$t('Güncelle')}}
        </b-button>
        <b-button block variant="danger" @click="deleteItem(row.item.id)" v-if="$can('delete', 'users')">
          {{$t('Sil')}}
        </b-button>
      </template>

    </b-table>
    <b-row v-if="!loading">
      <b-col cols="12" sm="6" class="text-center text-sm-left mb-1 mt-1">
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">{{$t('Sayfa Başı')}}</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="[3,5,10,25,50]"
            style="width:60px;"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" class="mb-1 mt-1">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          align="right"
          :total-rows="totalRows"
          class="my-0 d-sm-flex d-none"
        />
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          align="center"
          :total-rows="totalRows"
          class="my-0 d-sm-none d-flex"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BTable, BCard, BSpinner, BButton, BModal, VBModal, BInputGroupAppend, BFormInput, BFormSelect, BInputGroup, BFormGroup, BPagination, VBTooltip  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import UserForm from './UserForm.vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VSelect from 'vue-select'
import VueJsonExcel from 'vue-json-excel'
export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BSpinner,
    BButton,
    BModal,
    BInputGroupAppend, 
    BFormInput, 
    BInputGroup,
    BFormSelect,
    BFormGroup, 
    BPagination,
    VSelect,
    UserForm,
    VueJsonExcel
  },
  directives:{
    'b-modal' : VBModal,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      items: null,
      filteredItems:null,
      loading: 1,
      updateItem:null,
      modalTitle: "",
      filterOn:[],
      selected:[],
      filter:'',
      perPage:5,
      currentPage:1,
      totalRows: 0,
      fields:[
        {key: 'id', label: 'ID', sortable:true},
        {key: 'email', label: this.$t('Email'), sortable:true},
        {key: 'full_name', label: this.$t('İsim') + ' & ' + this.$t('Soyisim') , sortable:true},
        {key: 'gender', label: this.$t('Cinsiyet'), sortable:true},
        {key: 'code', label: this.$t('Kayıt Kodu'), sortable:true},
        {key: 'phone', label: this.$t('Telefon')},
        {key: 'address', label: this.$t('Adres')},
        {key: 'role_id', label: this.$t('Rol')},
        {key: 'actions', label: this.$t('İşlemler'), tdClass:'noprint', thClass:'noprint'},
      ],
      options:[
        {value:"email", title: this.$t("Email")},
        {value:"full_name", title: this.$t("İsim") + ' & ' + this.$t('Soyisim') },
        {value:"code", title: this.$t("Kayıt Kodu")},
        {value:"phone", title: this.$t("Telefon")},
        {value:"address", title: this.$t("Adres")},
        {value:"role_id", title: this.$t("Rol")},
      ],
      xlsIcon:require('@/assets/images/excell.png'),
      xlsFields:{
                  'id': 'ID',
                  'email': this.$t('Email'), 
                  'full_name': this.$t('İsim')  + ' & ' + this.$t('Soyisim'),
                  'gender': this.$t('Cinsiyet'),
                  'code': this.$t('Kayıt Kodu'),
                  'phone': this.$t('Telefon'),
                  'address': this.$t('Adres'),
                  'role.name': this.$t('Rol')
                }
      }
    
  },
  mounted() {
    let reversed = {}
    Object.keys(this.xlsFields).forEach(key => {
        if(key == 'gender'){
          reversed[this.xlsFields[key]] = {
            field:key,
            callback: value => this.$t(value)
          }
        }else{
        reversed[this.xlsFields[key]] = key
        }
    });
    this.xlsFields = Object.assign({}, reversed)
    this.$store.dispatch('user/list_users').then(response => {
      this.items = response.data
      this.totalRows = this.items.length
      this.filteredItems = response.data
      this.loading = 0
    });
  },

  methods:{
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.filteredItems = filteredItems
    },
    newItem(){
      this.updateItem = {
        name: '',
        surname: '',
        gender: 'male',
        phone: '',
        email: '',
        role_id: 1,
        address: '',
        password: '',
        password_confirmation: ''
      }
      this.modalTitle = this.$t("Yeni Kullanıcı")
    },
    updateModal(item){
      this.updateItem = item
      this.modalTitle = this.$t("Kullanıcıyı Güncelle")
    },
    async addNewItem(item){
      if(!this.loading){
        this.loading = 1
        await axios.post('users/create', {
          name: item.name,
          surname: item.surname,
          phone: item.phone,
          email: item.email,
          role_id: item.role_id, 
          password: item.password,
          password_confirmation: item.password_confirmation,
          address: item.address,
          gender: item.gender
          })
          .then(response => {
            this.items.push(response.data)
            this.$refs['form-modal'].hide()
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Eklendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Yeni Kullanıcı')} ${response.data.name} ${response.data.surname} ${this.$t('Başarıyla Eklendi')}`,
              },
            })
            this.loading = 0
          })
          .catch(error => {
            if(error.response.status == 422){
                this.$refs['user-form'].$refs.addForm.setErrors(error.response.data.errors)
            }else{
              this.$refs['form-modal'].hide()
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Birşeyler Ters Gitti'),
                  icon: 'XIcon',
                  variant: 'danger',
                  text: `${this.$t('Yeni Kullanıcı')} ${this.$t('Eklenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
                },
              })
            }
            this.loading = 0
          })
      }
    },
    async update(item){
      if(!this.loading){
        this.loading = 1
        await axios.post('users/update', {
          user_id: item.id,
          name: item.name,
          surname: item.surname,
          phone: item.phone,
          email: item.email,
          role_id: item.role_id, 
          password: item.password,
          password_confirmation: item.password_confirmation,
          address: item.address,
          gender: item.gender
        })
          .then(response => {
            let i = this.items.findIndex(itm => itm.id == item.id)
            this.items[i] = Object.assign({}, response.data)
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Güncellendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Kullanıcı')} ${this.$t('Başarıyla Güncellendi')}`,
              },
            })
            this.loading = 0
            this.$refs['form-modal'].hide()
          })
          .catch(error => {
            if(error.response.status == 422){
                this.$refs['user-form'].$refs.addForm.setErrors(error.response.data.errors)
            }else{
              this.$refs['form-modal'].hide()
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Birşeyler Ters Gitti'),
                  icon: 'XIcon',
                  variant: 'danger',
                  text: `${this.$t('Kullanıcı')} ${this.$t('Güncellenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
                },
              })
            }
            this.loading = 0
          })
      }
    },
    deleteItem(id) {

      this.$swal.fire({
        title: this.$t("Emin misiniz?"),
        text: this.$t("user_delete_question"),
        icon: "question",
        backdrop: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: true,
        confirmButtonText: this.$t("Evet"),
        denyButtonText: this.$t("Hayır"),
      }).then(response => {
        if(response.isConfirmed){
          axios.delete('users/delete/'+id).then(() => {
            this.items = this.items.filter(item => item.id != id)
          })
        }
      })
      
      
    },
    print(){
      document.body.classList.add('printbody')
      let $printSection = document.getElementById("printSection");
      $printSection.innerHTML = this.$refs.usersTable.$el.innerHTML;
      window.print()
      document.body.classList.remove('printbody')
    },
  },
}
</script>

<style>
@media screen {
    #printSection {
        display: none;
    }
}
@media print {
    .printbody * {
        visibility:hidden;
    }
    #printSection, #printSection * {
        visibility:visible;
    }
    #printSection {
        position:absolute;
        left:0;
        top:0;
    }

    .noprint{
      display:none;
    }

}
</style>