var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":""}},[(_vm.loading)?_c('b-row',[_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"}})],1)],1):_vm._e(),(!_vm.loading)?_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"addForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('Yeni Bilet')))]),(!_vm.item.department_id)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("Departman Seç")))])]),_c('br'),_vm._l((_vm.departments),function(department){return _c('b-col',{key:department.id,staticClass:"text-center mt-2",attrs:{"cols":"6","xl":"2","lg":"3","md":"4","sm":"4"}},[_c('h5',{staticStyle:{"height":"30px"}},[_vm._v(_vm._s(department.name))]),_c('img',{staticClass:"w-100 cursor-pointer border p-1",staticStyle:{"height":"150px","width":"150px !important","display":"block","margin":"0 auto"},attrs:{"src":_vm.getLogo(department)},on:{"click":function($event){_vm.item.department_id = department.id}}}),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary"},on:{"click":function($event){_vm.item.department_id = department.id}}},[_vm._v(_vm._s(_vm.$t("Seç")))])],1)})],2):_vm._e(),(_vm.item.department_id)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Konu'),"vid":"subject","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":_vm.$t('Konu'),"label-size":"lg","label-for":"subject"}},[_c('b-form-input',{attrs:{"id":"subject","placeholder":_vm.$t('Konu'),"name":_vm.$t('Konu'),"state":errors.length > 0 ? false : null},model:{value:(_vm.item.subject),callback:function ($$v) {_vm.$set(_vm.item, "subject", $$v)},expression:"item.subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label-size":"lg","label":_vm.$t('Departman'),"label-for":"department_id"}},[_c('b-form-select',{attrs:{"id":"department_id","options":_vm.department_options},model:{value:(_vm.item.department_id),callback:function ($$v) {_vm.$set(_vm.item, "department_id", $$v)},expression:"item.department_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label-size":"lg","label":_vm.$t('Öncelik'),"label-for":"priority"}},[_c('b-form-select',{attrs:{"id":"priority","options":[
                { value: 0, text: _vm.$t('Düşük') },
                { value: 1, text: _vm.$t('Orta') },
                { value: 2, text: _vm.$t('Yüksek') },
                { value: 3, text: _vm.$t('Çok Yüksek') } ]},model:{value:(_vm.item.priority),callback:function ($$v) {_vm.$set(_vm.item, "priority", $$v)},expression:"item.priority"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Mesaj'),"vid":"message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":_vm.$t('Mesaj'),"label-size":"lg","label-for":"message"}},[_c('b-form-textarea',{attrs:{"id":"message","placeholder":_vm.$t('Mesaj'),"state":errors.length > 0 ? false : null},model:{value:(_vm.item.message),callback:function ($$v) {_vm.$set(_vm.item, "message", $$v)},expression:"item.message"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"my-2",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label-size":"lg","label":_vm.$t('Dosyalar'),"label-for":"files"}},[_c('b-form-file',{staticClass:"mt-1",attrs:{"id":"files","accept":".jpg, .png, .jpeg, .pdf, .doc, .docx, .webp, .gif","multiple":"","browse-text":_vm.$t('browse'),"placeholder":_vm.$t('no_file_chosen')},model:{value:(_vm.item.files),callback:function ($$v) {_vm.$set(_vm.item, "files", $$v)},expression:"item.files"}})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"offset-md":"4"}},[(!_vm.item.id)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"submit","variant":"primary","disabled":invalid},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.$t("Gönder"))+" ")]):_vm._e(),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$t("İptal"))+" ")])],1)],1):_vm._e()]}}],null,false,1191092696)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }