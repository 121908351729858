import axios from '@axios'

export default {
  namespaced: true,
  state: {
    userTitle: localStorage.getItem('userTitle') || 'Sehr geehrter',
    codeTitle: localStorage.getItem('codeTitle') || 'Ihr Kundennummer ist',
    content: localStorage.getItem('content') || 
`Sie können mit dieser Nummer auf hocos.com/kundenbereich im HOCOS-Kundenbereich registrieren.
Mit freundlichen Grüßen
HOCOS UG
`
  },
  getters: {},
  mutations: {
    updateUserTitle(state, newValue) {
      state.userTitle = newValue
      localStorage.setItem('userTitle', newValue)
    },
    updateCodeTitle(state, newValue) {
      state.codeTitle = newValue
      localStorage.setItem('codeTitle', newValue)
    },
    updateContent(state, newValue) {
      state.content = newValue
      localStorage.setItem('content', newValue)
    }
  },
  actions: {
    list() {
      return new Promise((resolve, reject) => {
        axios
          .get('/register-codes')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
 
  },
}
