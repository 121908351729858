export default [
  {
    path: '/general-settings/departments',
    name: 'departments',
    component: () => import('@/views/general-settings/departments/Departments.vue'),
    meta: {
      resource: 'departments',
      action: 'read',
      pageTitle: 'Departmanlar',
      breadcrumb: [
        {
          text: 'Genel Ayarlar',
        },
        {
          text: 'Departmanlar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/announcements',
    name: 'announcements',
    component: () => import('@/views/general-settings/announcements/Announcements.vue'),
    meta: {
      resource: 'announcements',
      action: 'read',
      pageTitle: 'Duyurular',
      breadcrumb: [
        {
          text: 'Genel Ayarlar',
        },
        {
          text: 'Duyurular',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/documents',
    name: 'documents',
    component: () => import('@/views/general-settings/documents/Documents.vue'),
    meta: {
      resource: 'documents',
      action: 'read',
      pageTitle: 'Dökümanlar',
      breadcrumb: [
        {
          text: 'Genel Ayarlar',
        },
        {
          text: 'Dökümanlar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/site-settings',
    name: 'site-settings',
    component: () => import('@/views/general-settings/site-settings/SiteSettings.vue'),
    meta: {
      resource: 'site_settings',
      action: 'read',
      pageTitle: 'Site Ayarları',
      breadcrumb: [
        {
          text: 'Genel Ayarlar',
        },
        {
          text: 'Site Ayarları',
          active: true,
        },
      ],
    },
  },

  {
    path: '/general-settings/users',
    name: 'users',
    component: () => import('@/views/general-settings/users/Users.vue'),
    meta: {
      resource: 'users',
      action: 'read',
      pageTitle: 'Kullanıcılar',
      breadcrumb: [
        {
          text: 'Genel Ayarlar',
        },
        {
          text: 'Kullanıcılar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/general-settings/roles',
    name: 'roles',
    component: () => import('@/views/general-settings/roles/Roles.vue'),
    meta: {
      resource: 'roles',
      action: 'read',
      pageTitle: 'Roller',
      breadcrumb: [
        {
          text: 'Genel Ayarlar',
        },
        {
          text: 'Roller',
          active: true,
        },
      ],
    },
  },

  {
    path: '/general-settings/language',
    name: 'translates',
    component: () => import('@/views/general-settings/translates/Translates.vue'),
    meta: {
      resource: 'language',
      action: 'read',
      pageTitle: 'Çeviriler',
      breadcrumb: [
        {
          text: 'Genel Ayarlar',
        },
        {
          text: 'Çeviriler',
          active: true,
        },
      ],
    },
  },

  
  {
    path: '/general-settings/register-codes',
    name: 'register-codes',
    component: () => import('@/views/general-settings/register-codes/RegisterCodes.vue'),
    meta: {
      resource: 'register_codes',
      action: 'read',
      pageTitle: 'Kayıt Kodları',
      breadcrumb: [
        {
          text: 'Genel Ayarlar',
        },
        {
          text: 'Kayıt Kodları',
          active: true,
        },
      ],
    },
  },

  {
    path: '/general-settings/contact',
    name: 'contact',
    component: () => import('@/views/general-settings/contact/Contact.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'İletişim',
      breadcrumb: [
        {
          text: 'İletişim',
          active: true,
        },
      ],
    },
  },

  {
    path: '/general-settings/categories',
    name: 'document-categories',
    component: () => import('@/views/general-settings/document-categories/DocumentCategories.vue'),
    meta: {
      resource: 'document_categories',
      action: 'read',
      pageTitle: 'Kategoriler',
      breadcrumb: [
        {
          text: 'Kategoriler',
          active: true,
        },
      ],
    },
  },
]
