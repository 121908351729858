<template>
  <b-card title="">
    <div id="printSection"></div>
    <b-row v-if="loading">
      <b-col md="12" class="text-center">
        <b-spinner
          style="width:3rem; height:3rem;"
        />
      </b-col>

    </b-row>
    <div class="w-100 text-right" v-if="!loading">

      <b-modal
        id="type-form-modal"
        centered
        :title="modalTitle"
        hide-footer
        ref="form-modal"
        no-close-on-backdrop
        v-if="$can('create', 'documents') || $can('update', 'documents')"
        size="lg"
      >
      <document-form @addNewItem="addNewItem" @update="update" @cancel="$refs['form-modal'].hide()" :item="updateItem"></document-form>
      </b-modal>
      <portal to="breadcrumb-right">
        <b-button
        @click = "newItem"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.type-form-modal
        variant="primary"
        v-if="$can('create', 'documents')"
        
      >{{$t('Yeni Döküman Ekle')}}</b-button>
      </portal>
      
    </div>
    <b-row v-if="!loading">
      <b-col md="5" cols="12" class="mt-2 mb-2">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            :placeholder="$t('Ara')"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">{{$t('Temizle')}}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      
      <b-col md="4" cols="12" class="mt-2 mb-2 d-none d-md-block">
        <v-select
            v-model="selected"
            multiple
            label="title"
            :placeholder="$t('Sütuna Göre Arayın')"
            :options="options"
          >
          <template v-slot:no-options>
            <div>{{ $t("nothing_found") }}</div>
          </template>
        </v-select> 
      </b-col>
      <b-col md="3" cols="12" class="mt-2 mb-2 text-center text-md-right">
      <vue-json-excel style="display:inline-block" :data="filteredItems" :fields="xlsFields" :name="$t('Dökümanlar') + '.xls'">
        <b-button class="mr-1" size="sm" variant="outline-primary" v-b-tooltip.hover.top="$t('excell')">
          <img :src="xlsIcon" style="width:18px" class="cursor-pointer">
        </b-button>
      </vue-json-excel>
      <b-button variant="outline-info" size="sm" v-b-tooltip.hover.top="$t('print')" @click="print">
            <feather-icon
              icon="PrinterIcon"
              size="18"
            />
      </b-button>
      </b-col>
    </b-row>
    <b-table v-if="!loading" 
          ref="documents"
          stacked
          :items="items" 
          :fields="fields"
          :per-page="perPage" 
          :current-page="currentPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          tbody-tr-class="border-bottom-primary border-top-primary"
          class="d-block d-lg-none">
      <template #cell(register_code)="row">
        {{row.item.user.code}}
      </template>
      <template #cell(owner)="row">
        {{row.item.user.full_name}}
      </template>
      <template #cell(category)="row">
        {{row.item.category_name}}
      </template>
      <template #cell(document)="row">
        <a :href="row.item.document" target="_blank">{{row.item.name}}</a>
      </template>
      <template #cell(actions)="row">
        <b-button class="mr-1 sbtn"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'documents')"
                
                  v-b-modal.type-form-modal>
          {{$t('Güncelle')}}
        </b-button>
        <b-button class="sbtn" variant="danger" @click="deleteItem(row.item.id)" v-if="$can('delete', 'documents')">
          {{$t('Sil')}}
        </b-button>
      </template>
    </b-table>
    <b-table v-if="!loading" 
             ref="documents"
             responsive
             :items="items" 
             :fields="fields"
             :per-page="perPage" 
              :current-page="currentPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              class="d-none d-lg-block">
      <template #cell(register_code)="row">
        {{row.item.user.code}}
      </template>
      <template #cell(owner)="row">
        {{row.item.user.full_name}}
      </template>
      <template #cell(category)="row">
        {{$t('document_category_'+row.item.category)}}
      </template>
      <template #cell(document)="row">
        <a :href="row.item.document" target="_blank">{{row.item.name}}</a>
      </template>
      <template #cell(actions)="row">
        <b-button class="mr-1 mb-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'documents')"
                  block
                  v-b-modal.type-form-modal>
          {{$t('Güncelle')}}
        </b-button>
        <b-button class="mb-1" block variant="danger" @click="deleteItem(row.item.id)" v-if="$can('delete', 'documents')">
          {{$t('Sil')}}
        </b-button>
      </template>
    </b-table>
    <b-row v-if="!loading">
      <b-col cols="12" sm="6" class="text-center text-sm-left mb-1 mt-1">
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">{{$t('Sayfa Başı')}}</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="[3,5,10,25,50]"
            style="width:60px;"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" class="mb-1 mt-1">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          align="right"
          :total-rows="totalRows"
          class="my-0 d-sm-flex d-none"
        />
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          align="center"
          :total-rows="totalRows"
          class="my-0 d-sm-none d-flex"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BTable, BCard, BSpinner, BButton, BModal, VBModal, BInputGroupAppend, BFormInput, BFormSelect, BInputGroup, BFormGroup, BPagination, VBTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DocumentForm from './DocumentForm.vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VSelect from 'vue-select'
import VueJsonExcel from 'vue-json-excel'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BSpinner,
    BButton,
    BModal,
    BInputGroupAppend, 
    BFormInput, 
    BInputGroup,
    BFormSelect,
    BFormGroup, 
    BPagination,
    VSelect,
    DocumentForm,
    VueJsonExcel,

  },
  directives:{
    'b-modal' : VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      items: null,
      categories:null,
      filteredItems:null,
      loading: 1,
      updateItem:null,
      modalTitle: "",
      filterOn:[],
      selected:[],
      filter:'',
      perPage:5,
      currentPage:1,
      totalRows: 0,
      fields:[
        {key: 'id', label: 'ID', sortable:true},
        {key: 'document', label: this.$t('Döküman'), sortable:true},
        {key: 'category_name', label: this.$t('Kategori'), sortable:true},
        {key: 'date_time', label: this.$t('Oluşturulma Tarihi'), sortable:true},
      ],
      options:[
        {value:"document", title: this.$t("Döküman")},
        {value:"category", title: this.$t("Kategori")},
      ],
      xlsIcon:require('@/assets/images/excell.png'),
      xlsFields:{
        'id': 'ID',
        'user.code': this.$t('Kayıt Kodu'),
        'user.full_name': this.$t('Döküman Sahibi'),
        'name':this.$t('Döküman'),
        'category_name': this.$t('Kategori'),
        'date_time': this.$t('Oluşturulma Tarihi')
      }

    }
  },
  mounted() {
    let reversed = {}
    Object.keys(this.xlsFields).forEach(key => {
      if(!((key == 'user.code' || key == 'user.full_name') && !this.$can('all', 'documents')))
        reversed[this.xlsFields[key]] = key
    });
    this.xlsFields = Object.assign({}, reversed)
    if(this.$can('all', 'documents')){
      this.fields.shift()
      this.fields.unshift({key: 'owner', label: this.$t('Döküman Sahibi'), sortable:true})
      this.fields.unshift({key: 'register_code', label: this.$t('Kayıt Kodu'), sortable:true})
      this.fields.unshift({key: 'id', label: 'ID', sortable:true},)
      this.options.unshift({value: 'owner', title: this.$t('Döküman Sahibi')})
      this.options.unshift({value: 'register_code', title: this.$t('Kayıt Kodu')})
    }
    if(this.$can('update', 'documents') || this.$can('delete', 'documents')){
      this.fields.push({key: 'actions', label: this.$t('İşlemler'), tdClass:'noprint', thClass:'noprint'})
    }
    this.$store.dispatch('documents/list').then(response => {
      this.items = response.data
      this.filteredItems = response.data
      this.totalRows = this.items.length
      this.loading = 0
    });
  },

  methods:{
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.filteredItems = filteredItems
      this.currentPage = 1
    },
    newItem(){
      this.updateItem = {name: '', category_id: null, user_id: null}
      this.modalTitle = this.$t("Yeni Döküman")
    },
    updateModal(item){
      this.updateItem = Object.assign({},item)
      this.modalTitle = this.$t("Dökümanı Güncelle")
    },
    async addNewItem(item, file){
      if(!this.loading){
        this.loading = 1
        this.$refs['form-modal'].hide()
        let formData = new FormData()
        formData.append('name', item.name)
        formData.append('category_id', item.category_id)
        formData.append('user_id', item.user_id.value)
        formData.append('file', file)
        await axios.post('documents/create', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            this.items.push(response.data)
            
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Eklendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Yeni Döküman')} ${response.data.name} ${this.$t('Başarıyla Eklendi')}`,
              },
            })
            this.loading = 0
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Birşeyler Ters Gitti'),
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$t('Yeni Döküman')} ${this.$t('Eklenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
              },
            })
            this.loading = 0
          })
      }
    },
    async update(item, file){
      if(!this.loading){
        this.loading = 1
        this.$refs['form-modal'].hide()
        let formData = new FormData()
        formData.append('name', item.name)
        formData.append('category_id', item.category_id)
        formData.append('user_id', item.user_id.value)
        formData.append('document_id', item.id)
        if(file) formData.append('file', file)

        await axios.post('documents/update', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then((response) => {
            let i = this.items.findIndex(itm => itm.id == item.id)
            this.items[i] = response.data
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Güncellendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Döküman')} ${this.$t('Başarıyla Güncellendi')}`,
              },
            })
            this.loading = 0
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Birşeyler Ters Gitti'),
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$t('Çeviri')} ${this.$t('Güncellenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
              },
            })
            this.loading = 0
          })
      }
    },
    deleteItem(id) {

      this.$swal.fire({
        title: this.$t("Emin misiniz?"),
        text: this.$t("document_delete_question"),
        icon: "question",
        backdrop: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: true,
        confirmButtonText: this.$t("Evet"),
        denyButtonText: this.$t("Hayır"),
      }).then(response => {
        if(response.isConfirmed){
          axios.delete('documents/delete/'+id).then(() => {
            this.items = this.items.filter(item => item.id != id)
          })
        }
      })
      
    },
      print(){
      document.body.classList.add('printbody')
      let $printSection = document.getElementById("printSection");
      $printSection.innerHTML = this.$refs.documents.$el.innerHTML;
      window.print()
      document.body.classList.remove('printbody')
    },

  },
}
</script>
<style>
@media screen {
    #printSection {
        display: none;
    }
}
@media print {
    .printbody * {
        visibility:hidden;
    }
    #printSection, #printSection * {
        visibility:visible;
    }
    #printSection {
        position:absolute;
        left:0;
        top:0;
    }

    .noprint{
      display:none;
    }

}
</style>