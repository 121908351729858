<template>
  <b-form @submit.prevent>
    <b-modal
        id="send-email-modal"
        centered
        :title="$t('Mail Gönder')"
        hide-footer
        ref="send-email-modal"
      >
    <send-email-modal @sendEmail="sendEmail" @cancel="$refs['send-email-modal'].hide()"/>
    </b-modal>
    <b-row>
      <b-col cols="6">
        <b-col cols="12">
          <b-form-group
            :label="$t('Kullanıcı Başlığı')"
            label-for="user_title"
            label-cols-md="3"
          >
            <b-form-input
              id="user_title"
              :placeholder="$t('Kullanıcı Başlığı')"
              v-model="user_title"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('Kod Başlığı')"
            label-for="code_title"
            label-cols-md="3"
          >
            <b-form-input
              id="code_title"
              :placeholder="$t('Kod Başlığı')"
              v-model="code_title"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('İçerik')"
            label-for="content"
            label-cols-md="3"
          >
            <b-form-textarea
              id="content"
              :placeholder="$t('İçerik')"
              v-model="content"
              rows="8"
            />
          </b-form-group>
        </b-col>
      </b-col>

      <b-col cols="6">

          <h4>{{$t('Önizleme')}}</h4>
        <div ref="preview">
          <div style="width:100%;">
            {{ user_title }}
          </div>
          <div style="width:100%;">
            {{ code_title }} <!-- <u style="font-weight:900">{{ item.code }}</u> -->
          </div>
          <div style="width:100%;">
            <pre style="font-family:inherit">{{
              content
            }}</pre>
          </div>
        </div>
      </b-col>

      <!-- submit and reset -->
      <b-col class="col-12 text-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="$refs['send-email-modal'].show()"
        >
          {{ $t("Mail Gönder") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="info"
          class="mr-1"
          @click="downloadPdf"
        >
          {{ $t("Pdf İndir") }}
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          @click="$emit('cancel')"
          variant="outline-secondary"
        >
          {{ $t("İptal") }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BModal
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SendEmailModal from './SendEmailModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BButton,
    BModal,
    SendEmailModal
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user_title: this.$store.state.registerCodes.userTitle,
      code_title: this.$store.state.registerCodes.codeTitle,
      content: this.$store.state.registerCodes.content,
    };
  },
  watch:{
    user_title: function(n){
      this.$store.commit('registerCodes/updateUserTitle', n)
    },
    code_title: function(n){
      this.$store.commit('registerCodes/updateCodeTitle', n)
    },
    content: function(n){
      this.$store.commit('registerCodes/updateContent', n)
    }
  },

  props: ["item"],
  methods: {
    async downloadPdf() {
      this.$toast({
        component: ToastificationContent,
        position: "bottom-right",
        props: {
          title: this.$t("Dosya Hazırlanıyor"),
          icon: "CheckIcon",
          variant: "success",
          text: this.$t('file_preparing_message'),
        },
      });
      const response = await axios.post("/register-codes/download-pdf", {
        code_id: this.item.id,
        content: `<style>
pre {
  white-space: pre-wrap;
  font-family: inherit;
}
</style>` + this.$refs.preview.innerHTML,
      }, {responseType: 'blob'});
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", this.item.code+".pdf");
      document.body.appendChild(link);
      link.click();
    },

    sendEmail(to,title){
      axios.post('register-codes/send-email', {to: to, title: title, content: `<style>
pre {
  white-space: pre-wrap;
  font-family: inherit;
}
</style>` + this.$refs.preview.innerHTML})
      this.$toast({
        component: ToastificationContent,
        position: "bottom-right",
        props: {
          title: this.$t("Email Gönderildi"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
      $refs['send-email-modal'].hide()
    }
  },
};
</script>

<style scoped>
pre {
  background: unset; 
  overflow: unset; 
  font-size: unset;
  color:inherit;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
</style>