<template>
<div>
      <b-modal
        id="add-file-modal"
        centered
        :title="$t('Dosya Ekle')"
        hide-footer
        ref="add-file-form-modal"
        size="md"
      >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
          
            <b-form-group
              :label="$t('Dosyaları Seçin')"
              label-for="files"
            >
            <b-form-file
              class="mt-1"
              id="newFiles"
              accept=".jpg, .png, .jpeg, .pdf, .doc, .docx, .webp, .gif"
              multiple
              v-model="newFiles"
              :browse-text="$t('browse')"
              :placeholder="$t('no_file_chosen')"
            />
          </b-form-group>
          </b-col>
        <b-col offset-md="4" class="mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="addFile"
          >
            {{ $t("Ekle") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="$refs['add-file-form-modal'].hide()"
            variant="outline-secondary"
          >
            {{ $t("İptal") }}
          </b-button>
        </b-col>
        </b-row>
      </b-form>
      </b-modal>
  <b-form @submit.prevent>
    <validation-observer ref="addForm" #default="{ invalid }">
      <b-row>
        <b-col cols="12">
          <b-form-group
          :label="$t('Departman')"
          label-for="department"
        >
          <b-form-select
            id="department"
            :options="department_options"
            v-model="item.department_id"
            v-if="!loading"
          />
          <b-spinner v-if="loading"/>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('Konu')"
            vid="subject"
            rules="required"
          >
            <b-form-group
              :label="$t('Konu')"
              label-for="subject"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="subject"
                :placeholder="$t('Konu')"
                v-model="item.subject"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('Durum')"
            label-for="status"
          >
            <b-form-select
              id="status"
              :options="[
                {value: 0, text: $t('Yeni')},
                {value: 1, text: $t('Yanıtlandı')},
                {value: 2, text: $t('Yeni Mesaj')},
                {value: 3, text: $t('Kapandı')},
              ]"
              v-model="item.status"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('Öncelik')"
            label-for="priority"
          >
            <b-form-select
              id="priority"
              :options="[
                {value: 0, text: $t('Düşük')},
                {value: 1, text: $t('Orta')},
                {value: 2, text: $t('Yüksek')},
                {value: 3, text: $t('Çok Yüksek')},
              ]"
              v-model="item.priority"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('Mesaj')"
            vid="message"
            rules="required"
          >
            <b-form-group
              :label="$t('Mesaj')"
              label-for="message"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-textarea
                id="message"
                :placeholder="$t('Mesaj')"
                v-model="item.message"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <div v-if="item.id">
          <h3 class="ml-1">{{$t('Yüklenmiş Dosyalar')}}</h3>
          <b-col cols="12" v-for="file in item.all_files" :key="file.id">
            <a :href="file.file" target="_blank">{{file.file.split('/').slice(-1)[0]}} </a> <a v-if="$can('deleteFile', 'tickets')" @click="deleteFile(file.id)" class="text-danger ml-2">            <feather-icon
                icon="TrashIcon"
                size="18"
              />{{$t('Bu Dosyayı Sil')}}</a>
          </b-col>
          <b-col cols="12" class="mt-1">
            <a @click="$refs['add-file-form-modal'].show()" class="text-success">
              <feather-icon
                icon="FilePlusIcon"
                size="18"
              />{{$t('Dosya Ekle')}}</a>
          </b-col>
        </div>

        <b-col cols="12" class="my-2" v-if="$can('deleteFile', 'tickets')">
          
            <b-form-group
              :label="$t('Dosyalar')"
              label-for="files"
            >
            <b-form-file
              class="mt-1"
              id="files"
              accept=".jpg, .png, .jpeg, .pdf, .doc, .docx, .webp, .gif"
              multiple
              v-model="item.files"
              :browse-text="$t('browse')"
              :placeholder="$t('no_file_chosen')"
            />
            <small v-if="item.all_files" class="text-danger">{{$t('updating_file_warning')}}</small>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4" class="mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="add"
            :disabled="invalid"
            v-if="!item.id"
          >
            {{ $t("Gönder") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="update"
            :disabled="invalid"
            v-if="item.id"
          >
            {{ $t("Kaydet") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="cancel"
            variant="outline-secondary"
          >
            {{ $t("İptal") }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-form>
</div>

</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BFormTextarea,
  BForm,
  BButton,
  BSpinner,
  BModal, 
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BModal,
    
  },
  directives: {
    Ripple,
  },
  mounted(){
    this.$store.dispatch('departments/list').then(response => {
      this.departments = response.data
      this.item.department_id = this.departments[0]?.id
      this.loading = 0
    });
  },
  data() {
    return {
      required,
      loading: 0,
      departments: null,
      newFiles: null,
    };
  },
  computed: {
    department_options(){
      if(this.departments){
        let options = []
        this.departments.forEach(item => options.push({value:item.id, text:item.name}))
        return options
      }
      return null
    }
  },
  props: ["item"],
  methods: {
    add() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit("addNewItem", this.item);
        }
      });
    },
    update() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit("update", this.item);
        }
      });
    },
    cancel() {
      this.$emit("cancel", this.item);
    },

    async addFile(){
      if(!this.loading){
        this.loading = 1
        this.$refs['add-file-form-modal'].hide()
        let formData = new FormData()
        formData.append('ticket_id', this.item.id)
        if(this.newFiles){
          this.newFiles.forEach(file => formData.append('files[]', file))
        }
        await axios.post('tickets/add-file', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then(response => {
            this.item = response.data
            
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Eklendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Dosyalar')} ${response.data.name} ${this.$t('Başarıyla Eklendi')}`,
              },
            })
            this.loading = 0
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Birşeyler Ters Gitti'),
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$t('Dosyalar')} ${this.$t('Eklenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
              },
            })
            this.loading = 0
          })
          this.$emit('updateTicket', this.item)
      }
    },

    deleteFile(id){
      this.$swal.fire({
        title: this.$t("Emin misiniz?"),
        text: this.$t("file_delete_question"),
        icon: "question",
        backdrop: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: true,
        confirmButtonText: this.$t("Evet"),
        denyButtonText: this.$t("Hayır"),
      }).then(response => {
        if(response.isConfirmed){
          axios.delete('tickets/delete-file/'+this.item.id+'/' + id).then(() => {
            this.item.all_files = this.item.all_files.filter(item => item.id != id)
          })
        }
      })
      this.$emit('updateTicket', this.item)
    }
  },
};
</script>
