<template>
 <b-row>

  <b-col cols="12">
    <b-form-input v-model="to" :placeholder="$t('Mail Adresi')" /> <br>
    <b-form-input v-model="title" :placeholder="$t('Mail Başlığı')" />
  </b-col>


  <b-col cols="12" class="text-center">
    <b-button variant="primary" @click="sendEmail" class="mt-1  mr-1">{{$t('Gönder')}}</b-button>
    <b-button variant="outline-secondary" @click="$emit('cancel')" class="mt-1">{{$t('İptal')}}</b-button>
  </b-col>

 </b-row>

</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */

import { BRow, BCol, BButton, BFormInput} from 'bootstrap-vue'
export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,

  },


  data() {
    return {
      to: '',
      title: ''
    }
  },
  mounted() {

  },
  methods: {

    sendEmail(){
      if(this.to == null || this.to == ''){
        this.$swal.fire({
        title: this.$t("Mail Girmediniz"),
        icon: "warning",
        confirmButtonText: this.$t("Tamam"),
      })
        return false
      }

      if(this.title == null || this.title == ''){
        this.$swal.fire({
        title: this.$t("Başlık Girmediniz"),
        icon: "warning",
        confirmButtonText: this.$t("Tamam"),
      })
        return false
      }

      this.$emit('sendEmail', this.to, this.title)
    }
  },
}
</script>