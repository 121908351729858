<template>
  <b-form @submit.prevent>
    <validation-observer ref="addForm" #default="{ invalid }">
      <b-row>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('Döküman Adı')"
            vid="name"
            rules="required"
          >
            <b-form-group
              :label="$t('Döküman Adı')"
              label-for="name"
              label-cols-md="3"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="name"
                :placeholder="$t('Döküman Adı')"
                v-model="item.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group
              :label="$t('Kategori')"
              label-for="category_id"
              label-cols-md="3">
              <b-form-select 
              id="category_id"
              v-model="item.category_id"
              :options="category_options"
              v-if="!loading"
              />
              <b-spinner v-if="loading" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('Kullanıcı')"
            vid="user_id"
            rules="required"
          >
            <b-form-group
              :label="$t('Kullanıcı')"
              label-for="user_id"
              label-cols-md="3"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <v-select
                v-if="!loading"
                id="user_id"
                label="title"
                :placeholder="$t('Kullanıcı')"
                v-model="item.user_id"
                :state="errors.length > 0 ? false : null"
                :options="user_options"
              /> 
              <b-spinner v-if="loading" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
            <b-form-group
              :label="$t('Döküman')"
              label-for="file"
              label-cols-md="3"
            >
            <b-form-file
              class="mt-1"
              id="file"
              accept=".jpg, .png, .jpeg, .pdf, .doc, .docx, .webp, .gif, pdf, doc, docx, xls, xlsx"
              v-model="file"
              :browse-text="$t('browse')"
              :placeholder="$t('no_file_chosen')"
            />
            <small v-if="item.file" class="text-danger">{{$t('updating_document_warning')}}</small>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4" class="mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="add"
            :disabled="invalid"
            v-if="!item.id"
          >
            {{ $t("Ekle") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="update"
            :disabled="invalid"
            v-if="item.id"
          >
            {{ $t("Kaydet") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="cancel"
            variant="outline-secondary"
          >
            {{ $t("İptal") }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BForm,
  BButton,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import VSelect from 'vue-select'

import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BSpinner,
    VSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      file: null,
      loading:1,
      users:null,
      categories:null
    };
  },
  computed: {
    user_options() {
      if (this.users) {
        let options = [];
        this.users.forEach((item) =>
          options.push({ value: item.id, title: item.full_name + ' (' + item.code + ')' })
        );
        return options;
      }
      return null;
    },
    category_options() {
      if (this.categories) {
        let options = [];
        this.categories.forEach((item) =>
          options.push({ value: item.id, text: item.name })
        );
        return options;
      }
      return null;
    },
  },
  props: ["item"],
  mounted(){
    this.$store.dispatch('user/list_users').then(response => {
      this.users = response.data
      if(this.item.user_id){
        this.item.user_id = {value: this.item.user_id, title: this.users.find(i => i.id == this.item.user_id)?.full_name + ' (' + this.users.find(i => i.id == this.item.user_id)?.code + ')'}
      }
      this.$store.dispatch('documents/list_categories').then(r => {
        this.categories = r.data
        if(!this.item.category_id) this.item.category_id = this.categories[0].id
        this.loading = 0
      })
      
    });
  },
  methods: {

    add() {
      if(!this.file){
        this.$swal.fire({
          title: this.$t("Döküman Seçmediniz"),
          icon: "warning",
          confirmButtonText: this.$t("Tamam"),
        })
        return
      }
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit("addNewItem", this.item, this.file);
        }
      });
    },
    update() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit("update", this.item, this.file);
        }
      });
    },
    cancel() {
      this.$emit("cancel", this.item);
    },
  },
};
</script>
