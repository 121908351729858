<template>
  <b-card title="">
    <div id="printSection"></div>
    <b-row v-if="loading">
      <b-col md="12" class="text-center">
        <b-spinner
          style="width:3rem; height:3rem;"
        />
      </b-col>

    </b-row>
    <div class="w-100 text-right" v-if="!loading">

      <b-modal
        id="type-form-modal"
        centered
        :title="modalTitle"
        hide-footer
        ref="form-modal"
        no-close-on-backdrop
        v-if="$can('create', 'register_codes') || $can('update', 'register_codes')"
        size="lg"
      >
      <register-code-form @addNewItem="addNewItem" @update="update" @cancel="$refs['form-modal'].hide()" :item="updateItem"></register-code-form>
      </b-modal>

      
      <b-modal
        id="pdf-form-modal"
        centered
        :title="modalTitle"
        hide-footer
        ref="pdf-form-modal"
        v-if="$can('create', 'register_codes')"
        size="xl"
      >
      <pdf-form @cancel="$refs['pdf-form-modal'].hide()" :item="updateItem"></pdf-form>
      </b-modal>
      <portal to="breadcrumb-right">
        <b-button
        @click = "newItem"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.type-form-modal
        variant="primary"
        v-if="$can('create', 'register_codes')"
        
      >{{$t('Yeni Kayıt Kodu Ekle')}}</b-button>
      </portal>
      
    </div>
    <b-row v-if="!loading">
      <b-col md="5" cols="12" class="mt-2 mb-2">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            :placeholder="$t('Ara')"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">{{$t('Temizle')}}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      
      <b-col md="4" cols="12" class="mt-2 mb-2 d-none d-md-block">
        <v-select
            v-model="selected"
            multiple
            label="title"
            :placeholder="$t('Sütuna Göre Arayın')"
            :options="options"
          >
          <template v-slot:no-options>
            <div>{{ $t("nothing_found") }}</div>
          </template>
        </v-select> 
      </b-col>
      <b-col md="3" cols="12" class="mt-2 mb-2 text-center text-md-right">
      
        <vue-json-excel :data="filteredItems" :fields="xlsFields" :name="$t('Kayıt Kodları') + '.xls'" style="display:inline-block">
          <b-button class="mr-1" size="sm" variant="outline-primary" v-b-tooltip.hover.top="$t('excell')">
          <img :src="xlsIcon" style="width:18px" class="cursor-pointer">
                </b-button>
          </vue-json-excel>


      <b-button variant="outline-info" size="sm" v-b-tooltip.hover.top="$t('print')" @click="print">
            <feather-icon
              icon="PrinterIcon"
              size="18"
        />
      </b-button>
      </b-col>

    </b-row>
    <b-table v-if="!loading" 
             stacked
             :items="items" 
             :fields="fields"
             :per-page="perPage" 
              :current-page="currentPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              sticky-header
              ref="codesTable"
              @filtered="onFiltered"
              tbody-tr-class="border-bottom-primary border-top-primary"
              class="d-block d-lg-none">
      <template #cell(user_id)="row">
        {{row.item.user_id ? row.item.user.full_name : $t('Henüz Hesap Oluşturulmamış')}}
      </template>
      <template #cell(address)="row">
        {{row.item.street}} <br>
        {{row.item.postcode}} {{row.item.city}}
      </template>
      <template #cell(role_id)="row">
        {{row.item.role.name}}
      </template>
      <template #cell(actions)="row">
        <b-button block class="mr-1 mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'register_codes')"
                  v-b-modal.type-form-modal>
          {{$t('Güncelle')}}
        </b-button>
        <b-button block variant="info" class="mr-1 mt-1" @click="pdfModal(row.item)" v-if="$can('create', 'register_codes')" v-b-modal.pdf-form-modal>
          PDF & Mail
        </b-button>
        <b-button block variant="danger" class="mr-1 mt-1" @click="deleteItem(row.item.id)" v-if="$can('delete', 'register_codes')">
          {{$t('Sil')}}
        </b-button>
      </template>
    </b-table>
    <b-table v-if="!loading" 
             responsive
             :items="items" 
             :fields="fields"
             :per-page="perPage" 
              :current-page="currentPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              sticky-header
              ref="codesTable"
              @filtered="onFiltered"
              class="d-none d-lg-block">
      <template #cell(user_id)="row">
        {{row.item.user_id ? row.item.user.full_name : $t('Henüz Hesap Oluşturulmamış')}}
      </template>
      <template #cell(address)="row">
        {{row.item.street}} <br>
        {{row.item.postcode}} {{row.item.city}}
      </template>
      <template #cell(role_id)="row">
        {{row.item.role.name}}
      </template>
      <template #cell(actions)="row">
        <b-button block class="mr-1 mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'register_codes')"
                  v-b-modal.type-form-modal>
          {{$t('Güncelle')}}
        </b-button>
        <b-button block variant="info" class="mr-1 mt-1" @click="pdfModal(row.item)" v-if="$can('create', 'register_codes')" v-b-modal.pdf-form-modal>
          PDF & Mail
        </b-button>
        <b-button block variant="danger" class="mr-1 mt-1" @click="deleteItem(row.item.id)" v-if="$can('delete', 'register_codes')">
          {{$t('Sil')}}
        </b-button>
      </template>
    </b-table>
    <b-row v-if="!loading">
      <b-col cols="12" sm="6" class="text-center text-sm-left mb-1 mt-1">
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">{{$t('Sayfa Başı')}}</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="[3,5,10,25,50]"
            style="width:60px;"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" class="mb-1 mt-1">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          align="right"
          :total-rows="totalRows"
          class="my-0 d-sm-flex d-none"
        />
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          align="center"
          :total-rows="totalRows"
          class="my-0 d-sm-none d-flex"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BTable, BCard, BSpinner, BButton, BModal, VBModal, BInputGroupAppend, BFormInput, BFormSelect, BInputGroup, BFormGroup, BPagination, VBTooltip} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RegisterCodeForm from './RegisterCodeForm.vue'
import PdfForm from './PdfForm.vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VSelect from 'vue-select'
import VueJsonExcel from 'vue-json-excel'
export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BSpinner,
    BButton,
    BModal,
    BInputGroupAppend, 
    BFormInput, 
    BInputGroup,
    BFormSelect,
    BFormGroup, 
    BPagination,
    VSelect,
    RegisterCodeForm,
    PdfForm,
    VueJsonExcel

  },
  directives:{
    'b-modal' : VBModal,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      items: null,
      loading: 1,
      updateItem:null,
      modalTitle: "",
      filterOn:[],
      selected:[],
      filter:'',
      perPage:5,
      currentPage:1,
      totalRows: 0,
      filteredItems:null,
      fields:[
        {key: 'id', label: 'ID', sortable:true},
        {key: 'code', label: this.$t('Kayıt Kodu'), sortable:true},
        {key: 'role_id', label: this.$t('Rol'), sortable:true},
        {key: 'user_id', label: this.$t('Kullanıcı'), sortable:true},
        {key: 'expected_user', label: this.$t('Beklenen Kullanıcı'), sortable:true},
        {key: 'address', label: this.$t('Adres'), sortable:true},
        {key: 'actions', label: this.$t('İşlemler'), tdClass:'noprint', thClass:'noprint'},
      ],
      options:[
        {value:"code", title: this.$t("Kayıt Kodu")},
        {value:"role_id", title: this.$t("Rol")},
        {value:"user_id", title: this.$t("Kullanıcı")},
        {value:"expected_user", title: this.$t("Beklenen Kullanıcı")},
        {value:"address", title: this.$t("Adres")},
      ],
      xlsIcon:require('@/assets/images/excell.png'),
      xlsFields:{
            'id': 'ID',
            'code': this.$t('Kayıt Kodu'),
            'role.name': this.$t('Rol'),
            'user.full_name':this.$t('Kullanıcı'),
            'expected_user': this.$t('Beklenen Kullanıcı'),
            'address': this.$t('Adres')
          }
    }
  },
  mounted() {
    let reversed = {}
    Object.keys(this.xlsFields).forEach(key => {
        reversed[this.xlsFields[key]] = key
    });
    this.xlsFields = Object.assign({}, reversed)
    this.$store.dispatch('registerCodes/list').then(response => {
      this.items = response.data
      this.filteredItems = response.data
      this.totalRows = this.items.length
      this.loading = 0
    });
  },

  methods:{
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.filteredItems = filteredItems
    },
    newItem(){
      this.updateItem = {code: Math.random().toString(36).slice(2,12), role_id: null, expected_user:'', street: '', postcode:'', city:'' }
      this.modalTitle = this.$t("Yeni Kayıt Kodu")
    },
    updateModal(item){
      this.updateItem = Object.assign({},item)
      this.modalTitle = this.$t("Kayıt Kodunu Güncelle")
    },
    pdfModal(item){
      this.updateItem = Object.assign({}, item)
      this.modalTitle = "PDF & Mail"
    },
    async addNewItem(item){
      if(!this.loading){
        this.loading = 1
        this.$refs['form-modal'].hide()
        await axios.post('register-codes/create', {code: item.code, role_id: item.role_id, expected_user:item.expected_user, street:item.street, postcode:item.postcode, city:item.city})
          .then(response => {
            this.items.push(response.data)
            
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Eklendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Yeni Kayıt Kodu')} ${response.data.name} ${this.$t('Başarıyla Eklendi')}`,
              },
            })
            this.loading = 0
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Birşeyler Ters Gitti'),
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$t('Yeni Kayıt Kodu')} ${this.$t('Eklenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
              },
            })
            this.loading = 0
          })
      }
    },
    async update(item){
      if(!this.loading){
        this.loading = 1
        this.$refs['form-modal'].hide()
        await axios.post('register-codes/update', {code: item.code, role_id: item.role_id, id: item.id, expected_user:item.expected_user, street:item.street, postcode:item.postcode, city:item.city})
          .then(() => {
            let i = this.items.findIndex(itm => itm.id == item.id)
            this.items[i] = item
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Güncellendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Kayıt Kodu')} ${this.$t('Başarıyla Güncellendi')}`,
              },
            })
            this.loading = 0
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Birşeyler Ters Gitti'),
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$t('Kayıt Kodu')} ${this.$t('Güncellenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
              },
            })
            this.loading = 0
          })
      }
    },
    deleteItem(id) {

      this.$swal.fire({
        title: this.$t("Emin misiniz?"),
        text: this.$t("register_code_delete_question"),
        icon: "question",
        backdrop: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: true,
        confirmButtonText: this.$t("Evet"),
        denyButtonText: this.$t("Hayır"),
      }).then(response => {
        if(response.isConfirmed){
          axios.delete('register-codes/delete/'+id).then(() => {
            this.items = this.items.filter(item => item.id != id)
          })
        }
      })
    },

    print(){
      document.body.classList.add('printbody')
      let $printSection = document.getElementById("printSection");
      $printSection.innerHTML = this.$refs.codesTable.$el.innerHTML;
      window.print()
      document.body.classList.remove('printbody')
    },
  },
}
</script>

<style>
@media screen {
    #printSection {
        display: none;
    }
}
@media print {
    .printbody * {
        visibility:hidden;
    }
    #printSection, #printSection * {
        visibility:visible;
    }
    #printSection {
        position:absolute;
        left:0;
        top:0;
    }

    .noprint{
      display:none;
    }

}
</style>