/* eslint-disable */
// prettier-ignore
import i18n from '@/libs/i18n'

export default {
  sayfalar: {
    key: 'title',
    data: [
      { title: i18n.t('Departmanlar'), route: { name: 'departments' }, icon: 'BriefcaseIcon'},
      { title: i18n.t('Biletlerim'), route: { name: 'my-tickets' }, icon: 'MessageSquareIcon'},
      { title: i18n.t('Tüm Biletler'), route: { name: 'all-tickets' }, icon: 'ClipboardIcon'},
      { title: i18n.t('Kullanıcılar'), route: { name: 'users' }, icon: 'UsersIcon'},
      { title: i18n.t('Roller'), route: { name: 'roles' }, icon: 'KeyIcon'},
      { title: i18n.t('Site Ayarları'), route: { name: 'site-settings' }, icon: 'SettingsIcon'},
      { title: i18n.t('Çeviriler'), route: { name: 'translates' }, icon: 'GlobeIcon'},
      { title: i18n.t('Kayıt Kodları'), route: { name: 'register-codes' }, icon: 'CodeIcon'},
      { title: i18n.t('Profil'), route: { name: 'profile' }, icon: 'UserIcon'},
      
    ],
  },

}
/* eslint-enable */
