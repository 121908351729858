<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Bilgilerim") }}</span>
      </template>
      <b-row v-if="loading">
        <b-col md="12" class="text-center">
          <b-spinner style="width: 3rem; height: 3rem" />
        </b-col>
      </b-row>
      <account-setting-general
        v-if="!loading"
        :item="item"
        @update="update"
        ref="general"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="LockIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Şifreyi Değiştir") }}</span>
      </template>

      <account-setting-password @update="changePassword" ref="password" />
    </b-tab>
    <!--/ change password tab -->

    <!-- notification -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="BellIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $t("Bildirim Ayarları") }}</span>
      </template>

      <account-setting-notification
        v-if="!loading"
        :settings="item.notification_settings"
        @update="changeSettings"
      />
      <b-row v-if="loading">
        <b-col md="12" class="text-center">
          <b-spinner style="width: 3rem; height: 3rem" />
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BSpinner, BRow, BCol } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";
import AccountSettingNotification from "./AccountSettingNotification.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";

export default {
  components: {
    BTabs,
    BTab,
    BSpinner,
    BRow,
    BCol,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingNotification,
  },
  data() {
    return {
      item: {},
      loading: 1,
      options: null,
    };
  },
  beforeCreate() {
    this.$http.get("/account-setting/data").then((res) => {
      this.options = res.data;
    });
    axios
      .get("/profile")
      .then((response) => {
        this.item = response.data;
        this.loading = 0;
      })
      .catch((e) => (this.loading = 0));
  },

  methods: {
    async update(item) {
      if (!this.loading) {
        this.loading = 1;
        await axios
          .post("profile/update", {
            name: item.name,
            surname: item.surname,
            phone: item.phone,
            address: item.address,
            gender:item.gender
          })
          .then((response) => {
            this.item = response.data;
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Başarıyla Güncellendi"),
                icon: "CheckIcon",
                variant: "success",
                text: `${this.$t("Bilgiler")} ${this.$t(
                  "Başarıyla Güncellendi"
                )}`,
              },
            });
            this.loading = 0;
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.$refs["general"].$refs.userForm.setErrors(
                error.response.data.errors
              );
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: this.$t("Birşeyler Ters Gitti"),
                  icon: "XIcon",
                  variant: "danger",
                  text: `${this.$t("Bilgiler")} ${this.$t(
                    "Güncellenirken Bir Hata Oluştu:"
                  )} ${error.response.data.errors}`,
                },
              });
            }
            this.loading = 0;
          });
      }
    },

    async changePassword(oldPassword, newPassword, confirmation) {
      if (!this.loading) {
        this.loading = 1;
        await axios
          .post("profile/change-password", {
            old_password: oldPassword,
            password: newPassword,
            password_confirmation: confirmation,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Başarıyla Güncellendi"),
                icon: "CheckIcon",
                variant: "success",
                text: `${this.$t("Şifre")} ${this.$t("Başarıyla Güncellendi")}`,
              },
            });
            this.$refs["password"].oldPassword = "";
            this.$refs["password"].newPassword = "";
            this.$refs["password"].confirmation = "";
            this.$refs["password"].$refs.passwordForm.reset();
            this.loading = 0;
          })
          .catch((error) => {
            if (error.response.status == 422) {
              this.$refs["password"].$refs.passwordForm.setErrors(
                error.response.data.errors
              );
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: this.$t("Birşeyler Ters Gitti"),
                  icon: "XIcon",
                  variant: "danger",
                  text: `${this.$t("Şifre")} ${this.$t(
                    "Güncellenirken Bir Hata Oluştu:"
                  )} ${error.response.data.errors}`,
                },
              });
            }
            this.loading = 0;
          });
      }
    },

    async changeSettings(settings) {
      if (!this.loading) {
        this.loading = 1;
        await axios
          .post("profile/change-settings", {
            email_language_en: settings.email_language_en,
            mail_on_ticket_created: settings.mail_on_ticket_created,
            mail_on_ticket_replied: settings.mail_on_ticket_replied,
            mail_on_ticket_closed: settings.mail_on_ticket_closed,
            mail_on_document_created: settings.mail_on_document_created,
            notify_on_ticket_created: settings.notify_on_ticket_created,
            notify_on_ticket_replied: settings.notify_on_ticket_replied,
            notify_on_ticket_closed: settings.notify_on_ticket_closed,
            notify_on_document_created: settings.notify_on_document_created,
          })
          .then(response => {
            this.item = response.data
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Başarıyla Güncellendi"),
                icon: "CheckIcon",
                variant: "success",
                text: `${this.$t("Ayarlar")} ${this.$t("Başarıyla Güncellendi")}`,
              },
            });

            this.loading = 0;
          })
          .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: this.$t("Birşeyler Ters Gitti"),
                  icon: "XIcon",
                  variant: "danger",
                  text: `${this.$t("Ayarlar")} ${this.$t(
                    "Güncellenirken Bir Hata Oluştu:"
                  )} ${error.response.data.errors}`,
                },
              });
            this.loading = 0;
          });
      }
    },
  },
};
</script>
