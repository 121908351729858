export default [
  {
    path: '/tickets/all-tickets',
    name: 'all-tickets',
    component: () => import('@/views/tickets/all-tickets/AllTickets.vue'),
    meta: {
      resource: 'tickets',
      action: 'read',
      pageTitle: 'Tüm Biletler',
      breadcrumb: [
        {
          text: 'Biletler',
        },
        {
          text: 'Tüm Biletler',
          active: true,
        },
      ],
    },
  },

  {
    path: '/tickets/my-tickets',
    name: 'my-tickets',
    component: () => import('@/views/tickets/my-tickets/MyTickets.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      pageTitle: 'Biletlerim',
      breadcrumb: [
        {
          text: 'Biletler',
        },
        {
          text: 'Biletlerim',
          active: true,
        },
      ],
    },
  },

  {
    path: '/tickets/details/:id',
    name: 'ticket-details',
    component: () => import('@/views/tickets/single-view/Details.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
      contentRenderer: 'sidebar-left',
      contentClass:'email-application'
    },
  },

  {
    path: '/tickets/new',
    name: 'create-ticket',
    component: () => import('@/views/tickets/single-view/Create.vue'),
    meta: {
      resource: 'Dashboard',
      action: 'read',
    },
  },

]
