<template>
  <div>
    <b-modal
        id="reply-form-modal"
        centered
        :title="$t('Yanıtı Güncelle')"
        hide-footer
        ref="reply-form-modal"
        size="lg"
        v-if=" $can('update', 'replies')"
      >
      <reply-form @replyUpdated="replyUpdated" @cancel="$refs['reply-form-modal'].hide()" :item="updateItem"></reply-form>
    </b-modal>
    <b-card>
      <b-row>
        <b-col cols="5">
          <b>{{ item.owner.full_name }}</b> <br />
          {{ item.owner.role.name }}
        </b-col>
        <b-col cols="7" class="text-right">
          <b-badge class="mr-1" v-if="item.priority == 0" variant="secondary">{{
            $t("Düşük")
          }}</b-badge>
          <b-badge class="mr-1" v-if="item.priority == 1" variant="primary">{{
            $t("Orta")
          }}</b-badge>
          <b-badge class="mr-1" v-if="item.priority == 2" variant="warning">{{
            $t("Yüksek")
          }}</b-badge>
          <b-badge class="mr-1" v-if="item.priority == 3" variant="danger">{{
            $t("Çok Yüksek")
          }}</b-badge>
          <b-badge class="mr-1" v-if="item.status == 0" variant="info">{{
            $t("Yeni")
          }}</b-badge>
          <b-badge class="mr-1" v-if="item.status == 1" variant="success">{{
            $t("Yanıtlandı")
          }}</b-badge>
          <b-badge class="mr-1" v-if="item.status == 2" variant="warning">{{
            $t("Yeni Mesaj")
          }}</b-badge>
          <b-badge class="mr-1" v-if="item.status == 3" variant="secondary">{{
            $t("Kapandı")
          }}</b-badge>
          {{ item.date_time }}
        </b-col>
        <b-col cols="12" class="mt-2">
          <h2>{{ item.subject }}</h2>
          {{ item.message }}
          <h3 class="mt-1" v-if="item.all_files.length">{{ $t("Yüklenmiş Dosyalar") }}</h3>
          <span v-for="file in item.all_files" :key="file.id" class="d-block">
            <a :href="file.file" target="_blank"
              >{{ file.file.split("/").slice(-1)[0] }}
            </a>
          </span>
        </b-col>
        <b-col cols="12" class="mt-2 text-center">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          class="mr-1"
          @click="closeTicket"
          v-if="item.status != 3"
        >
          {{ $t("Bileti Kapat") }}
        </b-button>
      </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-row v-if="loading">
        <b-spinner v-if="loading"/>
      </b-row>
      <b-form v-if="!loading" @submit.prevent>
        <validation-observer ref="replyForm" #default="{ invalid }">
          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="{ errors }"
                :name="$t('Mesaj')"
                vid="message"
                rules="required"
              >
                <b-form-group
                  :label="$t('Mesaj')"
                  label-for="message"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-textarea
                    id="message"
                    :placeholder="$t('Mesaj')"
                    v-model="replyMessage"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-form-group :label="$t('Dosyalar')" label-for="files">
                <b-form-file
                  class="mt-1"
                  id="files"
                  accept=".jpg, .png, .jpeg, .pdf, .doc, .docx, .webp, .gif"
                  multiple
                  v-model="replyFiles"
                  :browse-text="$t('browse')"
                  :placeholder="$t('no_file_chosen')"
                />
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12" class="mt-2 text-center">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1"
                @click="reply()"
                :disabled="invalid"
              >
                {{ $t("Yanıtla") }}
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </b-form>
    </b-card>
    <b-card>
      <b-row
        v-for="(reply, i) in item.replies"
        :key="reply.id"
        :class="i == 0 ? '' : 'mt-1'"
      >
        <b-col cols="5">
          <b>{{ reply.owner.full_name }}</b> <br />
          {{ reply.owner.role.name }}
        </b-col>
        <b-col cols="7" class="text-right">
          <a @click="deleteReply(reply.id)" class="mr-1 text-danger" v-if="$can('deleteReply', 'tickets')">{{$t('Yanıtı Sil')}}</a> 
          <a @click="updateReply(reply.id)" class="mr-1" v-if="$can('updateReply', 'tickets')">{{$t('Yanıtı Güncelle')}}</a>
          {{ reply.date_time }}
        </b-col>
        <hr />
        <b-col cols="12" class="mt-2">
          <p>{{ reply.message }}</p>
          <h4 v-if="reply.all_files.length">{{ $t("Yüklenmiş Dosyalar") }}</h4>
          <span v-for="file in reply.all_files" :key="file.id">
            <a :href="file.file" target="_blank"
              >{{ file.file.split("/").slice(-1)[0] }}
            </a><br>
          </span>
                  <hr/>
        </b-col>
      </b-row>
      <b-col cols="12" class="mt-2 text-center">

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="secondary"
          class="mr-1"
          @click="cancel"
        >
          {{ $t("Kapat") }}
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BFormTextarea,
  BForm,
  BButton,
  BSpinner,
  BCard,
  BModal,
  BBadge,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ReplyForm from './ReplyForm.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    BForm,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BModal,
    BBadge,
    ReplyForm
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      loading: 0,
      replyFiles: null,
      replyMessage: "",
      item: null,
      updateItem:null,
    };
  },

  props: ["ticket"],
  created(){
    this.item = Object.assign({}, this.ticket)
  },
  methods: {
    cancel() {
      this.$emit("cancel", this.item);
    },

    updateReply(reply_id){
      this.updateItem = this.item.replies.find(item => item.id == reply_id)
      this.updateItem.ticket_id = this.item.id
      this.$refs['reply-form-modal'].show();
    },

    replyUpdated(reply){
      let index = this.item.replies.findIndex(item => item.id == reply.id)
      this.item.replies[index] = Object.assign({}, reply)
      this.updateItem = Object.assign({}, this.item.replies[index])
      this.$emit('updateTicket', this.item)
    },

    async closeTicket(){

      if(!this.loading){
        this.loading = 1
        await axios.get('tickets/close-ticket/' + this.item.id)
          .then((response) => {
            this.$emit('updateTicket', response.data)
            this.item = response.data
            this.loading = 0
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Kapatıldı'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Bilet')} ${this.$t('Başarıyla Kapatıldı')}`,
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Birşeyler Ters Gitti'),
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$t('Bilet')} ${this.$t('Güncellenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
              },
            })
            this.loading = 0
          })
      }
    },

    deleteReply(id){
      this.$swal.fire({
        title: this.$t("Emin misiniz?"),
        text: this.$t("reply_delete_question"),
        icon: "question",
        backdrop: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: true,
        confirmButtonText: this.$t("Evet"),
        denyButtonText: this.$t("Hayır"),
      }).then(response => {
        if(response.isConfirmed){
          axios.delete('tickets/delete-reply/'+this.item.id + '/' + id).then(() => {
            this.item.replies = this.item.replies.filter(item => item.id != id)
            this.$emit('updateTicket', this.item)
          })
        }
      })
    },

    async reply() {
      if (!this.loading) {
        this.loading = 1;
        let formData = new FormData();
        formData.append("ticket_id", this.item.id);
        formData.append("message", this.replyMessage);
        if (this.replyFiles) {
          this.replyFiles.forEach((file) => formData.append("files[]", file));
        }
        await axios
          .post("tickets/reply", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            this.item = response.data
            this.replyFiles = null
            this.replyMessage = null
            this.$emit('updateTicket', this.item)
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Başarıyla Eklendi"),
                icon: "CheckIcon",
                variant: "success",
                text: `${this.$t("Yanıt")} ${response.data.name} ${this.$t(
                  "Başarıyla Eklendi"
                )}`,
              },
            });
            this.loading = 0;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Birşeyler Ters Gitti"),
                icon: "XIcon",
                variant: "danger",
                text: `${this.$t("Yanıt")} ${this.$t(
                  "Eklenirken Bir Hata Oluştu:"
                )} ${error.response.data.errors}`,
              },
            });
            this.loading = 0;
          });
      }
    },
  },
};
</script>
