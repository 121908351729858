<template>
  <section id="dashboard-analytics">
    <b-row class="match-height" v-if="!loading">
      <b-col cols="12">
        <h2 v-if="announcements.length">{{$t('Duyurular')}}</h2>
        <b-alert class="mt-2 p-1" v-for="announcement in announcements" :key="announcement.id" :variant="announcement.alert_variant" show>
            <b>{{$i18n.locale == 'de' ? announcement.title_de : announcement.title_en}}</b>
            <p class="mt-1" v-html="$i18n.locale == 'de' ? announcement.content_de : announcement.content_en"></p>
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="match-height" v-if="!loading">
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          color="primary"
          icon="ClipboardIcon"
          :statistic="tickets.length"
          :statistic-title="$t('Toplam Bilet Sayısı')"
        />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          color="success"
          icon="MessageSquareIcon"
          :statistic="tickets.filter((i) => i.status == 3).length"
          :statistic-title="$t('Toplam Kapatılan Bilet Sayısı')"
        />
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <statistic-card-vertical
          color="secondary"
          icon="MessageSquareIcon"
          :statistic="tickets.filter((i) => i.status != 3).length"
          :statistic-title="$t('Toplam Açık Bilet Sayısı')"
        />
      </b-col>
      <b-col xl="2" md="4" sm="6" v-if="$can('all', 'dashboard')">
        <statistic-card-vertical
          color="success"
          icon="BriefcaseIcon"
          :statistic="departments.length"
          :statistic-title="$t('Toplam Departman Sayısı')"
        />
      </b-col>
      <b-col xl="2" md="4" sm="6" v-if="$can('all', 'dashboard')">
        <statistic-card-vertical
          color="warning"
          icon="UserIcon"
          :statistic="users.length"
          :statistic-title="$t('Toplam Kullanıcı Sayısı')"
        />
      </b-col>
      <b-col xl="2" md="4" sm="6" v-if="$can('all', 'dashboard')">
        <statistic-card-vertical
          color="warning"
          icon="LockIcon"
          :statistic="roles.length"
          :statistic-title="$t('Toplam Rol Sayısı')"
        />
      </b-col>
    </b-row>

    <b-row class="match-height"  v-if="!loading">
      <b-col cols="12">
        <b-card>
          <h3>{{ $t("Durumuna Göre Biletler") }}</h3>
          <vue-apex-charts
            type="donut"
            height="350"
            :options="ticketChartOptions"
            :series="ticketChartSeries"
          />
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BAlert } from 'bootstrap-vue'
import axios from '@axios'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    StatisticCardVertical,
    VueApexCharts,
    BCardHeader, 
    BCardTitle, 
    BCardBody,
    BAlert
  },
  data() {
    return {
      loading: 1,
      tickets: null,
      users: null,
      departments: null,
      roles:null,
      announcements:null
    }
  },
  created() {
    axios.get('dashboard').then(response => {
      this.tickets = response.data.tickets
      this.users = response.data.users
      this.departments = response.data.departments
      this.roles = response.data.roles
          this.$store.dispatch('announcements/list').then(response => {
            this.announcements = response.data
            this.loading = 0
          });
      }).catch(error => {
      console.log(error)
      this.loading = 0
      })
  },

    computed: {
    ticketChartOptions(){
      let total = this.tickets?.length
      return {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        colors: [
          $themeColors.danger,
          $themeColors.success,
          $themeColors.secondary,

        ],
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: this.$t('Tümü'),
                  formatter() {
                    return total
                  },
                },
              },
            },
          },
        },
        labels: [this.$t('Yeni Biletler'), this.$t('Yanıtlanmış Biletler'), this.$t('Kapatılmış Biletler')],
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      }
    },

    ticketChartSeries(){
      let newTickets = this.tickets.filter(i => i.status == 0)
      let repliedTickets = this.tickets.filter(i => i.status == 1 || i.status == 2)
      let closedTickets = this.tickets.filter(i => i.status == 3)
      return [
      newTickets.length,
      repliedTickets.length,
      closedTickets.length
      ]
    },

  }

}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
</style>