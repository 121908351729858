<template>
    <b-form @submit.prevent>
      <validation-observer
            ref="addForm"
            #default="{invalid}"
          >
      <b-row>

      <b-col cols="12">
          <validation-provider
              #default="{ errors }"
              :name="$t('Rol Adı')"
              vid="name"
              rules="required"
          >
            <b-form-group
            :label="$t('Rol Adı')"
            label-for="name"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              id="name"
              :state="errors.length > 0 ? false:null"
              :placeholder="$t('Rol Adı')"
              v-model="item.name"
              class="w-25"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
      </b-col>
      <b-col cols="12">
        <b-form-group>
          <b-form-radio-group
            v-model="all"
            :options="[{value:false, text:$t('Bazı Yetkiler')}, {value:true, text:$t('Tüm Yetkiler')}]"
            name="all-radios"
          />
        </b-form-group>
      </b-col>

      <b-row v-if="!all" style="margin:unset">

      <b-col lg="3" md="6">
        <h5 class="mb-1">{{$t('Tüm Biletler')}}</h5>
        <b-form-group>
          <b-form-checkbox
            id="read-tickets"
            v-model="checks['tickets'].read"
            name="read-tickets"
          >
            {{$t('Görüntüle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="create-tickets"
            v-model="checks['tickets'].create"
            name="create-tickets"
          >
            {{$t('Oluştur')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="update-tickets"
            v-model="checks['tickets'].update"
            name="update-tickets"
          >
            {{$t('Düzenle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="delete-tickets"
            v-model="checks['tickets'].delete"
            name="delete-tickets"
          >
            {{$t('Sil')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="delete-file-tickets"
            v-model="checks['tickets'].deleteFile"
            name="delete-file-tickets"
          >
            {{$t('Dosya Sil')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="update-reply-tickets"
            v-model="checks['tickets'].updateReply"
            name="update-reply-tickets"
          >
            {{$t('Yanıt Düzenle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="delete-reply-tickets"
            v-model="checks['tickets'].deleteReply"
            name="delete-reply-tickets"
          >
            {{$t('Yanıt Sil')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="6">
        <h5 class="mb-1">{{$t('Kullanıcılar')}}</h5>
        <b-form-group>
          <b-form-checkbox
            id="read-users"
            v-model="checks['users'].read"
            name="read-users"
          >
            {{$t('Görüntüle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="create-users"
            v-model="checks['users'].create"
            name="create-users"
          >
            {{$t('Oluştur')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="update-users"
            v-model="checks['users'].update"
            name="update-users"
          >
            {{$t('Düzenle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="delete-users"
            v-model="checks['users'].delete"
            name="delete-users"
          >
            {{$t('Sil')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="6">
        <h5 class="mb-1">{{$t('Roller')}}</h5>
        <b-form-group>
          <b-form-checkbox
            id="read-roles"
            v-model="checks['roles'].read"
            name="read-roles"
          >
            {{$t('Görüntüle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="create-roles"
            v-model="checks['roles'].create"
            name="create-roles"
          >
            {{$t('Oluştur')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="update-roles"
            v-model="checks['roles'].update"
            name="update-roles"
          >
            {{$t('Düzenle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="delete-roles"
            v-model="checks['roles'].delete"
            name="delete-roles"
          >
            {{$t('Sil')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="6">
        <h5 class="mb-1">{{$t('Departmanlar')}}</h5>
        <b-form-group>
          <b-form-checkbox
            id="read-departments"
            v-model="checks['departments'].read"
            name="read-departments"
          >
            {{$t('Görüntüle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="create-departments"
            v-model="checks['departments'].create"
            name="create-departments"
          >
            {{$t('Oluştur')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="update-departments"
            v-model="checks['departments'].update"
            name="update-departments"
          >
            {{$t('Düzenle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="delete-departments"
            v-model="checks['departments'].delete"
            name="delete-departments"
          >
            {{$t('Sil')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col lg="3" md="6">
        <h5 class="mb-1">{{$t('Kategoriler')}}</h5>
        <b-form-group>
          <b-form-checkbox
            id="read-document-categories"
            v-model="checks['document_categories'].read"
            name="read-document-categories"
          >
            {{$t('Görüntüle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="create-document_categories"
            v-model="checks['document_categories'].create"
            name="create-document-categories"
          >
            {{$t('Oluştur')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="update-document-categories"
            v-model="checks['document_categories'].update"
            name="update-document-categories"
          >
            {{$t('Düzenle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="delete-document-categories"
            v-model="checks['document_categories'].delete"
            name="delete-document-categories"
          >
            {{$t('Sil')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      
      <b-col lg="3" md="6">
        <h5 class="mb-1">{{$t('Kayıt Kodları')}}</h5>
        <b-form-group>
          <b-form-checkbox
            id="read-register-codes"
            v-model="checks['register_codes'].read"
            name="read-register-codes"
          >
            {{$t('Görüntüle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="create-register-codes"
            v-model="checks['register_codes'].create"
            name="create-register-codes"
          >
            {{$t('Oluştur')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="update-register-codes"
            v-model="checks['register_codes'].update"
            name="update-register-codes"
          >
            {{$t('Düzenle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="delete-register-codes"
            v-model="checks['register_codes'].delete"
            name="delete-register-codes"
          >
            {{$t('Sil')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="6">
        <h5 class="mb-1">{{$t('Çeviriler')}}</h5>
        <b-form-group>
          <b-form-checkbox
            id="read-language"
            v-model="checks['language'].read"
            name="read-language"
          >
            {{$t('Görüntüle')}}
          </b-form-checkbox>
        </b-form-group>
        <!--
        <b-form-group>
          <b-form-checkbox
            id="create-language"
            v-model="checks['language'].create"
            name="create-language"
          >
            {{$t('Oluştur')}}
          </b-form-checkbox>
        </b-form-group>
        -->
        <b-form-group>
          <b-form-checkbox
            id="update-language"
            v-model="checks['language'].update"
            name="update-language"
          >
            {{$t('Düzenle')}}
          </b-form-checkbox>
        </b-form-group>
        <!--
        <b-form-group>
          <b-form-checkbox
            id="delete-language"
            v-model="checks['language'].delete"
            name="delete-language"
          >
            {{$t('Sil')}}
          </b-form-checkbox>
        </b-form-group>
        -->
      </b-col>
      <b-col lg="3" md="6">
        <h5 class="mb-1">{{$t('Duyurular')}}</h5>
        <b-form-group>
          <b-form-checkbox
            id="read-announcements"
            v-model="checks['announcements'].read"
            name="read-announcements"
          >
            {{$t('Görüntüle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="create-announcements"
            v-model="checks['announcements'].create"
            name="create-announcements"
          >
            {{$t('Oluştur')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="update-announcements"
            v-model="checks['announcements'].update"
            name="update-announcements"
          >
            {{$t('Düzenle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="delete-announcements"
            v-model="checks['announcements'].delete"
            name="delete-announcements"
          >
            {{$t('Sil')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="6">
        <h5>{{$t('Dökümanlar')}}</h5>
        <b-form-group>
          <b-form-radio-group
            v-model="checks['documents'].all"
            :options="[{value:false, text: $t('Kendi Dökümanları')}, {value:true, text: $t('Tüm Dökümanlar')}]"
            name="documents-radios"
            stacked
          />
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="read-documents"
            v-model="checks['documents'].read"
            name="read-documents"
          >
            {{$t('Görüntüle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="create-documents"
            v-model="checks['documents'].create"
            name="create-documents"
          >
            {{$t('Oluştur')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="update-documents"
            v-model="checks['documents'].update"
            name="update-documents"
          >
            {{$t('Düzenle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="delete-documents"
            v-model="checks['documents'].delete"
            name="delete-documents"
          >
            {{$t('Sil')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="6">
        <h5>{{$t('Kontrol Paneli')}}</h5>
         <b-form-group>
          <b-form-radio-group
            v-model="checks['dashboard'].all"
            :options="[{value:false, text: $t('Kendi Bilgileri')}, {value:true, text: $t('Tüm Bilgiler')}]"
            name="dashboard-radios"
            stacked
          />
        </b-form-group>
      </b-col>
      <b-col lg="3" md="6">
        <h5 class="mb-1">{{$t('Site Ayarları')}}</h5>
        <b-form-group> 
          <b-form-checkbox
            id="read-site-settings"
            v-model="checks['site_settings'].read"
            name="read-site-settings"
          >
            {{$t('Görüntüle')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="update-site-settings"
            v-model="checks['site_settings'].update"
            name="update-site-settings"
          >
            {{$t('Düzenle')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      <b-col lg="3" md="6">
        <h5>{{$t('Navigasyon Başlıkları')}}</h5>
        <small>{{$t('Eğer kullanıcının bir başlık altında yetkisi yoksa o başlık iznini kaldırın.')}}</small>
        <b-form-group class="mt-2">
          <b-form-checkbox
            id="read-setting-title"
            v-model="checks['setting_title'].read"
            name="read-setting-title"
          >
            {{$t('Biletler')}}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="read_ticket_title"
            v-model="checks['ticket_title'].read"
            name="read_ticket_title"
          >
            {{$t('Genel Ayarlar')}}
          </b-form-checkbox>
        </b-form-group>
      </b-col>
      </b-row>
        <!-- submit and reset -->
        <b-col cols="12" class="text-center">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click = "add"
            :disabled="invalid"
            v-if="!item.id"
          >
            {{$t('Ekle')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click = "update"
            :disabled="invalid"
            v-if="item.id"
          >
            {{$t('Kaydet')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="cancel"
            variant="outline-secondary"
          >
            {{$t('İptal')}}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
    </b-form>

</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required} from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      checks:[],
      all:false,
      required,
      item: this.role,
    };
  },

  created(){
    this.checks['users'] = {read: false, create: false, update: false, delete: false }
    this.checks['roles'] = {read: false, create: false, update: false, delete: false }
    this.checks['departments'] = {read: false, create: false, update: false, delete: false }
    this.checks['announcements'] = {read: false, create: false, update: false, delete: false }
    this.checks['documents'] = {read: false, create: false, update: false, delete: false, all:false }
    this.checks['document_categories'] = {read: false, create: false, update: false, delete: false, all:false }
    this.checks['language'] = {read: false, create: false, update: false, delete: false }
    this.checks['register_codes'] = {read: false, create: false, update: false, delete: false }
    this.checks['tickets'] = {read: false, create: false, update: false, delete: false, deleteFile: false, updateReply:false, deleteReply: false }
    this.checks['dashboard'] = {all: false, }
    this.checks['site_settings'] = {read: false, update:false,}
    this.checks['setting_title'] = {read: false}
    this.checks['ticket_title'] = {read: false}

    this.role.permissions.forEach(item => {
      if(this.checks[item.subject] !== undefined && this.checks[item.subject][item.action] !== undefined){
        this.checks[item.subject][item.action] = true
      }
      if(item.subject == "all" && item.action == "manage"){
        this.all = true
      }
    })
  },
  props: ["role"],
  watch:{
    all:{
      handler(){
        if(this.all){
          this.checks = []
          this.checks['all'] = {'manage': true}
          this.$parent.$parent.$parent.$refs['form-modal'].size="sm"
          document.getElementById('name').classList.remove('w-25')
        }else{
          this.checks = []
          this.checks['users'] = {read: false, create: false, update: false, delete: false }
          this.checks['roles'] = {read: false, create: false, update: false, delete: false }
          this.checks['dashboard'] = {read: false, }
          this.checks['site_settings'] = {read: false, update:false,}
          this.checks['announcements'] = {read: false, create: false, update: false, delete: false }
          this.checks['documents'] = {read: false, create: false, update: false, delete: false, all:false }
          this.checks['document_categories'] = {read: false, create: false, update: false, delete: false, all:false }
          this.checks['language'] = {read: false, create: false, update: false, delete: false}
          this.checks['departments'] = {read: false, create: false, update: false, delete: false }
          this.checks['register_codes'] = {read: false, create: false, update: false, delete: false }
          this.checks['tickets'] = {read: false, create: false, update: false, delete: false, deleteFile: false, updateReply:false, deleteReply: false }
          this.checks['setting_title'] = {read: false}
          this.checks['ticket_title'] = {read: false}
          this.$parent.$parent.$parent.$refs['form-modal'].size="xl"
          document.getElementById('name').classList.add('w-25')

          this.role.permissions.forEach(item => {
            if(this.checks[item.subject] !== undefined && this.checks[item.subject][item.action] !== undefined){
              this.checks[item.subject][item.action] = true
            }
          })
        }
      }
    }
  },
  methods: {
    add() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit('addNewItem', this.item, this.checks)
        }
      })
    },
    update(){
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit('update', this.item, this.checks)
        }
      })
    },
    cancel(){
      this.$emit('cancel')
    }
  },
};
</script>