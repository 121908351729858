<template>
  <b-form @submit.prevent>
    <validation-observer ref="addForm" #default="{ invalid }">
      <b-row>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('İngilizce Başlık')"
            vid="title_en"
            rules="required"
          >
            <b-form-group
              :label="$t('İngilizce Başlık')"
              label-for="title_en"
              label-cols-md="3"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="title_en"
                :placeholder="$t('İngilizce Başlık')"
                v-model="item.title_en"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

                <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('İngilizce İçerik')"
            vid="content_en"
            rules="required"
          >
            <b-form-group
              :label="$t('İngilizce İçerik')"
              label-for="content_en"
              label-cols-md="3"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <quill-editor
                id="content_en"
                :placeholder="$t('İngilizce İçerik')"
                v-model="item.content_en"
                :state="errors.length > 0 ? false : null"
                :options="editorOption"
              >
                <div id="toolbar" slot="toolbar">
                  <!-- Add a bold button -->
                  <button class="ql-bold">Bold</button>
                  <button class="ql-italic">Italic</button>

                </div>
              </quill-editor>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('Almanca Başlık')"
            vid="title_de"
            rules="required"
          >
            <b-form-group
              :label="$t('Almanca Başlık')"
              label-for="title_de"
              label-cols-md="3"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="title_de"
                :placeholder="$t('Almanca Başlık')"
                v-model="item.title_de"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('Almanca İçerik')"
            vid="content_de"
            rules="required"
          >
            <b-form-group
              :label="$t('Almanca İçerik')"
              label-for="content_de"
              label-cols-md="3"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <quill-editor
                id="content_de"
                :placeholder="$t('Almanca İçerik')"
                v-model="item.content_de"
                :state="errors.length > 0 ? false : null"
                :options="editorOption2"
              >
                <div id="toolbar2" slot="toolbar">
                  <!-- Add a bold button -->
                  <button class="ql-bold">Bold</button>
                  <button class="ql-italic">Italic</button>


                </div>
              </quill-editor>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('Görüntüleme Rengi')"
            label-for="alert_variant"
            label-cols-md="3"
          >
            <b-form-select
              id="alert_variant"
              :options="variantOptions"
              v-model="item.alert_variant"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group :label="$t('Sıra')" label-for="rank" label-cols-md="3">
            <b-form-input
              id="rank"
              :placeholder="$t('Sıra')"
              v-model="item.rank"
              type="number"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <h3>{{ $t("Önizleme") }}</h3>
          <h4>{{ $t("İngilizce") }}</h4>
          <b-alert show :variant="item.alert_variant" class="mt-2 p-1">
            <b>{{ item.title_en }}</b>
            <p class="mt-1" v-html="item.content_en"></p>
          </b-alert>
          <h4>{{ $t("Almanca") }}</h4>
          <b-alert show :variant="item.alert_variant" class="mt-2 p-1">
            <b>{{ item.title_de }}</b>
            <p class="mt-1" v-html="item.content_de"></p>
          </b-alert>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4" class="mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="add"
            :disabled="invalid"
            v-if="!item.id"
          >
            {{ $t("Ekle") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="update"
            :disabled="invalid"
            v-if="item.id"
          >
            {{ $t("Kaydet") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="cancel"
            variant="outline-secondary"
          >
            {{ $t("İptal") }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BForm,
  BButton,
  BFormTextarea,
  BFormSelect,
  BAlert,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BFormTextarea,
    BFormSelect,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      variantOptions: [
        "primary",
        "secondary",
        "success",
        "danger",
        "warning",
        "info",
        "dark",
      ],
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      editorOption2: {
        modules: {
          toolbar: "#toolbar2",
        },
      },
    };
  },

  props: ["item"],
  methods: {
    add() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit("addNewItem", this.item);
        }
      });
    },
    update() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit("update", this.item);
        }
      });
    },
    cancel() {
      this.$emit("cancel", this.item);
    },
  },
};
</script>
