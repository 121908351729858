<template>
  <b-card title="">
    <b-row v-if="loading">
      <b-col md="12" class="text-center">
        <b-spinner
          style="width:3rem; height:3rem;"
        />
      </b-col>

    </b-row>
    <div class="w-100 text-right" v-if="!loading">

      <b-modal
        id="type-form-modal"
        centered
        :title="modalTitle"
        hide-footer
        ref="form-modal"
        no-close-on-backdrop
        v-if="$can('create', 'language') || $can('update', 'language')"
      >
      <translate-form @addNewItem="addNewItem" @update="update" @cancel="$refs['form-modal'].hide()" :item="updateItem"></translate-form>
      </b-modal>
      <portal to="breadcrumb-right">
        <!--
        <b-button
        @click = "newItem"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.type-form-modal
        variant="primary"
        v-if="$can('create', 'language')"
        
      >{{$t('Yeni Çeviri Ekle')}}</b-button>
      -->
      </portal>
      
    </div>
    <b-row v-if="!loading">
      <b-col cols="12" md="6" class="mt-2 mb-2">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            :placeholder="$t('Ara')"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">{{$t('Temizle')}}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
      
      <b-col md="6" cols="12" class="mt-2 mb-2 d-none d-md-block">
        <v-select
            v-model="selected"
            multiple
            label="title"
            :placeholder="$t('Sütuna Göre Arayın')"
            :options="options"
          >
          <template v-slot:no-options>
            <div>{{ $t("nothing_found") }}</div>
          </template>
        </v-select> 
      </b-col>
    </b-row>
    <b-table v-if="!loading" 
             stacked
             :items="items" 
             :fields="fields"
             :per-page="perPage" 
              :current-page="currentPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              class="d-block d-lg-none"
              tbody-tr-class="border-bottom-primary border-top-primary"
              @filtered="onFiltered"
              >
      <template #cell(actions)="row">
        <b-button class="mr-1 mb-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'language')"
                  v-b-modal.type-form-modal>
          {{$t('Güncelle')}}
        </b-button>
        <!--
        <b-button class="mb-1" variant="danger" @click="deleteItem(row.item.id)" v-if="$can('delete', 'language')">
          {{$t('Sil')}}
        </b-button>
        -->
      </template>
    </b-table>
    <b-table v-if="!loading" 
             responsive
             :items="items" 
             :fields="fields"
             :per-page="perPage" 
              :current-page="currentPage"
              :filter="filter"
              :filter-included-fields="filterOn"
              class="d-none d-lg-block"
              @filtered="onFiltered">
      <template #cell(actions)="row">
        <b-button class="mr-1 mb-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'language')"
                  v-b-modal.type-form-modal>
          {{$t('Güncelle')}}
        </b-button>
        <!--
        <b-button class="mb-1" variant="danger" @click="deleteItem(row.item.id)" v-if="$can('delete', 'language')">
          {{$t('Sil')}}
        </b-button>
        -->
      </template>
    </b-table>
        <b-row v-if="!loading">
      <b-col cols="12" sm="6" class="text-center text-sm-left mb-1 mt-1">
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block text-sm-left mr-50">{{$t('Sayfa Başı')}}</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="[3,5,10,25,50]"
            style="width:60px;"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" class="mb-1 mt-1">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          align="right"
          :total-rows="totalRows"
          class="my-0 d-sm-flex d-none"
        />
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          align="center"
          :total-rows="totalRows"
          class="my-0 d-sm-none d-flex"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BTable, BCard, BSpinner, BButton, BModal, VBModal, BInputGroupAppend, BFormInput, BFormSelect, BInputGroup, BFormGroup, BPagination} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TranslateForm from './TranslateForm.vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VSelect from 'vue-select'
export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BSpinner,
    BButton,
    BModal,
    BInputGroupAppend, 
    BFormInput, 
    BInputGroup,
    BFormSelect,
    BFormGroup, 
    BPagination,
    VSelect,
    TranslateForm,

  },
  directives:{
    'b-modal' : VBModal,
    Ripple,
  },
  data() {
    return {
      items: null,
      loading: 1,
      updateItem:null,
      modalTitle: "",
      filterOn:[],
      selected:[],
      filter:'',
      perPage:5,
      currentPage:1,
      totalRows: 0,
      fields:[
        {key: 'id', label: 'ID', sortable:true},
        //{key: 'key', label: this.$t('Metin'), sortable:true},
        {key: 'en', label: this.$t('İngilizce'), sortable:true},
        {key: 'de', label: this.$t('Almanca'), sortable:true},
        {key: 'actions', label: this.$t('İşlemler')},
      ],
      options:[
        //{value:"key", title: this.$t("Metin")},
        {value:"en", title: this.$t("İngilizce")},
        {value:"de", title: this.$t("Almanca")},
      ]
    }
  },
  mounted() {

    this.$store.dispatch('language/list').then(response => {
      this.items = response.data
      this.totalRows = this.items.length
      this.loading = 0
    });
  },

  methods:{
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    newItem(){
      this.updateItem = {key: '', en: '', de: ''}
      this.modalTitle = this.$t("Yeni Çeviri")
    },
    updateModal(item){
      this.updateItem = Object.assign({},item)
      this.modalTitle = this.$t("Çeviriyi Güncelle")
    },
    async addNewItem(item){
      if(!this.loading){
        this.loading = 1
        this.$refs['form-modal'].hide()
        await axios.post('language/create', {key: item.key, en: item.en, de: item.de})
          .then(response => {
            this.items.push(response.data)
            
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Eklendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Yeni Çeviri')} ${response.data.name} ${this.$t('Başarıyla Eklendi')}`,
              },
            })
            this.loading = 0
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Birşeyler Ters Gitti'),
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$t('Yeni Çeviri')} ${this.$t('Eklenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
              },
            })
            this.loading = 0
          })
      }
    },
    async update(item){
      if(!this.loading){
        this.loading = 1
        this.$refs['form-modal'].hide()
        await axios.post('language/update', {key: item.key, tr: item.tr, en: item.en, de: item.de, fr: item.fr, pt: item.pt, id: item.id})
          .then(() => {
            let i = this.items.findIndex(itm => itm.id == item.id)
            this.items[i] = item
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Güncellendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Çeviri')} ${this.$t('Başarıyla Güncellendi')}`,
              },
            })
            this.loading = 0
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Birşeyler Ters Gitti'),
                icon: 'XIcon',
                variant: 'danger',
                text: `${this.$t('Çeviri')} ${this.$t('Güncellenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
              },
            })
            this.loading = 0
          })
      }
    },
    deleteItem(id) {

      this.$swal.fire({
        title: this.$t("Emin misiniz?"),
        text: this.$t("language_delete_question"),
        icon: "question",
        backdrop: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: true,
        confirmButtonText: this.$t("Evet"),
        denyButtonText: this.$t("Hayır"),
      }).then(response => {
        if(response.isConfirmed){
          axios.delete('language/delete/'+id).then(() => {
            this.items = this.items.filter(item => item.id != id)
          })
        }
      })
      
      
    },
  },
}
</script>
