<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <img :src="logo" height="45px" draggable="false" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
        :style="{
          backgroundImage: 'url(' + imgUrl + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }"
      >
        <div v-if="announcements" class="w-100">
          <h2 v-if="announcements.length">{{ $t("Duyurular") }}</h2>
          <b-alert
            class="mt-2 p-1"
            v-for="announcement in announcements"
            :key="announcement.id"
            :variant="announcement.alert_variant"
            show
          >
            <b>{{
              $i18n.locale == "de"
                ? announcement.title_de
                : announcement.title_en
            }}</b>
            <p class="mt-1" v-html="$i18n.locale == 'de' ? announcement.content_de : announcement.content_en"></p>
          </b-alert>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg p-lg-3">
        <b-col sm="8" md="6" lg="12" class="mx-auto login-locale">
          <div class="mb-5 text-center" style="list-style-type: none">
            <locale />
          </div>

          <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
            {{ $t("app_title") }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("login_subtitle") }}
          </b-card-text>

          <!--<b-alert variant="primary" show>
            <div class="alert-body font-small-2">
              <a
                @click="
                  userEmail = 'admin@peopdigital.net';
                  password = '12345678';
                "
              >
                <small class="mr-50"
                  ><span class="font-weight-bold">{{ $t("Admin") }}:</span>
                  admin@peopdigital.net | 12345678</small
                > </a
              ><br />
              <a
                @click="
                  userEmail = 'customer@peopdigital.net';
                  password = '12345678';
                "
              >
                <small class="mr-50"
                  ><span class="font-weight-bold">{{ $t("Müşteri") }}:</span>
                  customer@peopdigital.net | 12345678</small
                >
              </a>
              <a
                @click="
                  userEmail = 'employee@peopdigital.net';
                  password = '12345678';
                "
              >
                <small class="mr-50"
                  ><span class="font-weight-bold">{{ $t("Employee") }}:</span>
                  employee@peopdigital.net | 12345678</small
                > </a
              ><br />
              <a
                @click="
                  userEmail = 'manager@peopdigital.net';
                  password = '12345678';
                "
              >
                <small class="mr-50"
                  ><span class="font-weight-bold">{{ $t("Manager") }}:</span>
                  manager@peopdigital.net | 12345678</small
                >
              </a>
              <a
                @click="
                  userEmail = 'hr@peopdigital.net';
                  password = '12345678';
                "
              >
                <small class="mr-50"
                  ><span class="font-weight-bold"
                    >{{ $t("Human Resources") }}:</span
                  >
                  hr@peopdigital.net | 12345678</small
                >
              </a>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="$t('login_clue')"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10"
            />
          </b-alert>-->

          <!-- form -->
          <validation-observer ref="loginForm" #default="{ invalid }">
            <b-form class="auth-login-form mt-2" @submit.prevent="login">
              <!-- email -->
              <b-form-group label="Email" label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Email')"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    :placeholder="$t('Email')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t("Şifre") }}</label>
                  <b-link :to="{ name: 'auth-forgot-password' }">
                    <small>{{ $t("Şifremi Unuttum") }}</small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('Şifre')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ $t("Beni Hatırla") }}
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                class="auth-button"
                variant="null"
                block
                :disabled="invalid"
                v-if="!loading"
              >
                {{ $t("Giriş Yap") }}
              </b-button>
              <b-button
                class="auth-button"
                disabled
                block
                v-if="loading"
                variant="null"
              >
                <b-spinner small />
                {{ $t("Yükleniyor") }}...
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t("Hesabınız yok mu?") }}</span>
            <b-link :to="{ name: 'auth-register' }">
              <span>&nbsp;{{ $t("Kayıt Ol") }}</span>
            </b-link>
          </p>
          <p class="clearfix mb-0">
            <span class="d-block d-md-inline-block mt-25 float-right">
              <span class="d-inline-block mt-2" style="font-size:12px;">
                 {{ $t("Powered by") }}
                <a href="https://peop.de">PEOP</a></span
              >
            </span>
          </p>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import Locale from "@/layouts/vertical/nav-components/Locale.vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  mounted() {
    this.$store.dispatch("announcements/list").then((response) => {
      this.announcements = response.data;
      this.loading = 0;
    });
  },
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/hocos-bg.png"),
      logo: require("@/assets/images/logo/logo.png"),
      loading: 0,
      // validation rules
      required,
      email,
      announcements: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/hocos-bg.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      this.loading = 1;
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
              remember: this.status,
            })
            .then((response) => {
              this.$ability.update(response.data.userPermissions);
              this.$store.dispatch("user/login", {
                data: response.data.userData,
                permissions: response.data.userPermissions,
              });

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router.replace("/").then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `${this.$t("Hoşgeldin")} ${
                      response.data.userData.surname
                    }`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `${this.$t("Rolünüz")} ${
                      response.data.userData.roleName
                    }`,
                  },
                });
              });
            })
            .catch((error) => {
              this.loading = 0;
              let errors = error.response.data.errors
              for(let key in errors){
                errors[key] = this.$t(errors[key])
              }
              if (error.response.status == 422)
                this.$refs.loginForm.setErrors(errors);
              else console.log(error);
            });
        } else {
          this.loading = 0;
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
@media screen and (max-width: 990px) {
  .login-locale {
    margin-top: 6rem;
  }
}
</style>