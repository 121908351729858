<template>
  <b-card title="">
    <b-row v-if="loading">
      <b-col md="12" class="text-center">
        <b-spinner
          style="width:3rem; height:3rem;"
        />
      </b-col>

    </b-row>
      <b-modal
        id="type-form-modal"
        centered
        :title="modalTitle"
        hide-footer
        ref="form-modal"
        size="xl"
        no-close-on-backdrop
        v-if="$can('create', 'roles') || $can('update', 'roles')"
      >
      <role-form @addNewItem="addNewItem" @update="update" @cancel="$refs['form-modal'].hide()" :role="updateItem"></role-form>
      </b-modal>
      <portal to="breadcrumb-right">
        <b-button
        @click = "newItem"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.type-form-modal
        variant="primary"
        v-if="!loading && $can('create', 'roles')"
      >{{$t('Yeni Rol Ekle')}}</b-button>
      </portal>
      

    <b-table v-if="!loading" stacked :items="items" :fields="fields" class="d-block d-lg-none" tbody-tr-class="border-bottom-primary border-top-primary">
      <template #cell(name)="row">
        {{row.item.name}}
      </template>
      <template #cell(actions)="row">
        <b-button class="mr-1 sbtn"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'roles')"
                  v-b-modal.type-form-modal>
                  
          {{$t('Güncelle')}}
        </b-button>
        <b-button class="sbtn" variant="danger" @click="deleteItem(row.item.id)"  v-if="$can('delete', 'roles')">
          {{$t('Sil')}}
        </b-button>
      </template>

    </b-table>

    <b-table v-if="!loading" responsive :items="items" :fields="fields" class="d-none d-lg-block">
      <template #cell(name)="row">
        {{row.item.name}}
      </template>
      <template #cell(actions)="row">
        <b-button class="mr-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  @click="updateModal(row.item)"
                  v-if="$can('update', 'roles')"
                  v-b-modal.type-form-modal>
                  
          {{$t('Güncelle')}}
        </b-button>
        <b-button variant="danger" @click="deleteItem(row.item.id)"  v-if="$can('delete', 'roles')">
          {{$t('Sil')}}
        </b-button>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import { BRow, BCol, BTable, BCard, BSpinner, BButton, BModal, VBModal  } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RoleForm from './RoleForm.vue'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BSpinner,
    BButton,
    BModal,
    RoleForm,

  },
  directives:{
    'b-modal' : VBModal,
    Ripple,
  },
  data() {
    return {
      items: null,
      loading: 1,
      updateItem:null,
      modalTitle: "",
      fields:[
        {key: 'id', label: 'ID', sortable:true},
        {key: 'name', label: this.$t('Rol Adı'), sortable:true},
        {key: 'actions', label: this.$t('İşlemler')},
      ]
    }
  },
  mounted() {

    this.$store.dispatch('user/list_roles').then(response => {
      this.items = response.data
      this.loading = 0
    });
  },

  methods:{
    newItem(){
      this.updateItem = {
        name: '',
        permissions: [],
      }
      this.modalTitle = this.$t("Yeni Rol")
    },
    updateModal(item){
      this.updateItem = Object.assign({},item)
      this.modalTitle = this.$t("Rolü Güncelle")
    },
    async addNewItem(item, permissions){
      if(!this.loading){
        this.loading = 1
        await axios.post('roles/create', {
          name: item.name,
          permissions: Object.assign({},permissions)
          })
          .then(response => {
            this.items.push(response.data)
            this.$refs['form-modal'].hide()
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Eklendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Yeni Rol')} ${response.data.name} ${this.$t('Başarıyla Eklendi')}`,
              },
            })
          })
          .catch(error => {
              this.$refs['form-modal'].hide()
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Birşeyler Ters Gitti'),
                  icon: 'XIcon',
                  variant: 'danger',
                  text: `${this.$t('Yeni Rol')} ${this.$t('Eklenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
                },
              })
          })        
          this.loading = 0
      }
    },
    async update(item, permissions){
      if(!this.loading){
        this.loading = 1
        await axios.post('roles/update', {
          role_id: item.id,
          name: item.name,
          permissions: Object.assign({},permissions)
          })
          .then(response => {
            let i = this.items.findIndex(itm => itm.id == item.id)
            this.items[i] = response.data
            this.$refs['form-modal'].hide()
            this.$toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: this.$t('Başarıyla Güncellendi'),
                icon: 'CheckIcon',
                variant: 'success',
                text: `${this.$t('Rol')} ${this.$t('Başarıyla Güncellendi')}`,
              },
            })
          })
          .catch(error => {
              this.$refs['form-modal'].hide()
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Birşeyler Ters Gitti'),
                  icon: 'XIcon',
                  variant: 'danger',
                  text: `${this.$t('Rol')} ${this.$t('Güncellenirken Bir Hata Oluştu:')} ${error.response.data.errors}`,
                },
              })
          })        
          this.loading = 0
      }
    },
    deleteItem(id) {

      this.$swal.fire({
        title: this.$t("Emin misiniz?"),
        text: this.$t("role_delete_question"),
        icon: "question",
        backdrop: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showDenyButton: true,
        confirmButtonText: this.$t("Evet"),
        denyButtonText: this.$t("Hayır"),
      }).then(response => {
        if(response.isConfirmed){
          if(id == 1 || id == 2){
            this.$swal.fire({
              title: this.$t("Rol Silinemez"),
              text: this.$t("Varsayılan roller silinemez!"),
              icon: "error",
              confirmButtonText: this.$t("Tamam"),
            })
          }else{
            axios.delete('roles/delete/'+id).then(() => {
              this.items = this.items.filter(item => item.id != id)
            })
          }

        }
      })
      
      
    },
  },
}
</script>
