<template>
  <b-card>
    <!-- form -->
    <b-form class="mt-2">
      <validation-observer
          ref="userForm"
          #default="{invalid}"
      >
      <b-row>
        <b-col sm="6">
          <validation-provider
              #default="{ errors }"
              :name="$t('İsim')"
              vid="name"
              rules="required"
          >
          <b-form-group
            :label="$t('İsim')"
            label-for="name"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              id="name"
              :state="errors.length > 0 ? false:null"
              :placeholder="$t('İsim')"
              v-model="item.name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <validation-provider
              #default="{ errors }"
              :name="$t('Soyisim')"
              vid="surname"
              rules="required"
          >
            <b-form-group
            :label="$t('Soyisim')"
            label-for="surname"
            :class="errors.length > 0 ? 'is-invalid':null"
          >
            <b-form-input
              id="surname"
              :state="errors.length > 0 ? false:null"
              :placeholder="$t('Soyisim')"
              v-model="item.surname"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
          {{item.email}}
          </b-form-group>
        </b-col>
        <b-col sm="6">
            <b-form-group
            :label="$t('Telefon')"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              :placeholder="$t('Telefon')"
              v-model="item.phone"
            />
            </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="$t('Cinsiyet')">
          <b-form-radio-group v-model="item.gender" :options="[
                                                          { value: 'male', text: $t('male') },
                                                          { value: 'female', text: $t('female') },
                                                          { value: 'neutral', text: $t('neutral') }
                                                        ]" name="gender" />
        </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('Adres')"
            label-for="address"
            v-if="!item.address_confirmed"
          >
            <b-form-input
              id="address"
              :placeholder="$t('Adres')"
              v-model="item.address"
            />
          </b-form-group>
          <p v-if="item.address_confirmed"><b>{{$t('Adres')}}</b>: {{item.address}}</p>
        </b-col>

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="invalid"
            @click="$emit('update', item)"
          >
            {{$t('Kaydet')}}
          </b-button>
        </b-col>
      </b-row>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BFormRadioGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from '@validations'

export default {
  components: {
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  props: ['item'],
  data() {
    return {
      required
    }
  },
  methods: {

  },

}
</script>
