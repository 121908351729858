<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo" :to="{name: 'login'}">
        <img :src="logo" height="45px" draggable="false" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
        :style="{
          backgroundImage: 'url(' + imgUrl + ')',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }"
      >
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1">
            {{ $t("Şifremi Unuttum") }} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("forgot_password_text") }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                :label="$t('Email')"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button type="submit" variant="null" class="auth-button" block :disabled="!!loading">
                {{ loading ? $t("Yükleniyor") : $t("Sıfırlama linki gönder") }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login' }">
              <feather-icon icon="ChevronLeftIcon" />
              {{ $t("Girişe Geri Dön") }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "@axios";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      sideImg: require("@/assets/images/pages/hocos-bg.png"),
      logo: require("@/assets/images/logo/logo.png"),
      // validation
      required,
      email,
      loading: 0,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/hocos-bg.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
     validationForm() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.loading = 1
          axios
            .post("forgot-password", { email: this.userEmail })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: this.$t("Gönderildi"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: `${this.$t(
                    "Size bir şifre sıfırlama emaili gönderdik."
                  )}`,
                },
              });
              this.loading = 0;
              this.$router.push({name: "auth-login"})
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "bottom-right",
                props: {
                  title: this.$t("Birşeyler Ters Gitti"),
                  icon: "XIcon",
                  variant: "danger",
                  text: ` ${this.$t("Mail gönderilirken bir hata oluştu:")} ${
                    this.$t('mail_does_not_exist')
                  }`,
                },
              });
              this.loading = 0;
              this.$refs.simpleRules.setErrors({'email': this.$t('mail_does_not_exist')});
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
