import axios from '@axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {},
  mutations: {

  },
  actions: {
    list() {
      return new Promise((resolve, reject) => {
        axios
          .get('/documents')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    list_categories() {
      return new Promise((resolve, reject) => {
        axios
          .get('/document-categories')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
