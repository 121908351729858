<template>
    <b-form @submit.prevent>
      <validation-observer
            ref="addForm"
            #default="{invalid}"
          >
      <b-row>
        <!--
        <b-col cols="12">
            <validation-provider
                #default="{ errors }"
                name="key"
                vid="key"
                rules="required"
            >
              <b-form-group
              :label="$t('Metin')"
              label-for="key"
              label-cols-md="3"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="key"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('Metin')"
                v-model="item.key"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
        </b-col>
        -->
        <b-col cols="12">
              <b-form-group
              :label="$t('İngilizce')"
              label-for="en"
              label-cols-md="3"
            >
              <b-form-input
                id="en"
                :placeholder="$t('İngilizce')"
                v-model="item.en"
              />
              </b-form-group>
        </b-col>

        <b-col cols="12">
              <b-form-group
              :label="$t('Almanca')"
              label-for="de"
              label-cols-md="3"
            >
              <b-form-input
                id="de"
                :placeholder="$t('Almanca')"
                v-model="item.de"
              />
              </b-form-group>
        </b-col>
        
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click = "add"
            :disabled="invalid"
            v-if="!item.id"
          >
            {{$t('Ekle')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click = "update"
            :disabled="invalid"
            v-if="item.id"
          >
            {{$t('Kaydet')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="cancel"
            variant="outline-secondary"
          >
            {{$t('İptal')}}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
    </b-form>

</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    };
  },

  props: ["item"],
  methods: {
    add() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit('addNewItem', this.item)
        }
      })
    },
    update(){
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit('update', this.item)
        }
      })
    },
    cancel(){
      this.$emit('cancel', this.item)
    }
  },
};
</script>
