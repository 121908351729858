<template>
  <layout-vertical ref="child">
    <router-view :key="$route.fullPath"/>
      <div slot="navbar" class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
          <li class="nav-item">
            <b-link class="nav-link" @click="toggleVerticalMenuActive">
              <feather-icon icon="MenuIcon" size="21" />
            </b-link>
          </li>
        </ul>

        <b-navbar-nav class="nav align-items-center ml-auto">
          <locale />
          <dark-Toggler class="d-none d-lg-block" />
          <!--<search-bar /> -->
          <notification-dropdown />
          <user-dropdown />
        </b-navbar-nav>
    </div>
    <app-customizer v-if="showCustomizer" slot="customizer" />
  </layout-vertical>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import LayoutVertical from "@core/layouts/layout-vertical/LayoutVertical.vue";
import AppCustomizer from "@core/layouts/components/app-customizer/AppCustomizer.vue";
import Locale from "./nav-components/Locale.vue";
import SearchBar from "./nav-components/SearchBar.vue";
import DarkToggler from "./nav-components/DarkToggler.vue";
import UserDropdown from "./nav-components/UserDropdown.vue";
import NotificationDropdown from "./nav-components/NotificationDropdown.vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    UserDropdown,
    NotificationDropdown,
    DarkToggler,
    SearchBar,
    Locale,
    BLink,
    BNavbarNav,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },

  methods:{
    toggleVerticalMenuActive(){
      this.$refs.child.toggleVerticalMenuActive()
    }
  },

};
</script>
