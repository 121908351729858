<template>
  <b-form @submit.prevent>
    <validation-observer ref="addForm" #default="{ invalid }">
      <b-row>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="code"
            vid="code"
            rules="required"
          >
            <b-form-group
              :label="$t('Kayıt Kodu')"
              label-for="code"
              label-cols-md="3"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="code"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Kayıt Kodu')"
                v-model="item.code"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('Beklenen Kullanıcı')"
            label-for="expected_user"
            label-cols-md="3"
          >
            <b-form-input
              id="expected_user"
              :placeholder="$t('İsim') + ' ' + $t('Soyisim')"
              v-model="item.expected_user"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group :label="$t('Rol')" label-for="role" label-cols-md="3">
            <b-form-select
              id="role"
              :options="role_options"
              v-model="item.role_id"
              v-if="!loading"
            />
            <b-spinner v-if="loading" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="$t('Sokak & No')"
            label-for="register-street"
            label-cols-md="3"
          >
            <b-form-input
              id="register-street"
              v-model="item.street"
              :name="$t('Sokak & No')"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('Posta Kodu')"
            label-for="register-postcode"
            label-cols-md="3"
          >
            <b-form-input
              id="register-postcode"
              v-model="item.postcode"
              :name="$t('Posta Kodu')"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('Şehir')"
            label-for="register-city"
            label-cols-md="3"
          >
            <b-form-input
              id="register-city"
              v-model="item.city"
              :name="$t('Şehir')"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="add"
            :disabled="invalid"
            v-if="!item.id"
          >
            {{ $t("Ekle") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="update"
            :disabled="invalid"
            v-if="item.id"
          >
            {{ $t("Kaydet") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="cancel"
            variant="outline-secondary"
          >
            {{ $t("İptal") }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      roles: null,
      loading: 1,
      city: "",
      street: "",
      postcode: "",
    };
  },
  mounted() {
    this.$store.dispatch("user/list_roles").then((response) => {
      this.roles = response.data;
      this.item.role_id = this.roles[0].id;
      this.loading = 0;
    });
  },

  computed: {
    role_options() {
      if (this.roles) {
        let options = [];
        this.roles.forEach((item) =>
          options.push({ value: item.id, text: item.name })
        );
        return options;
      }
      return null;
    },
  },

  props: ["item"],
  methods: {
    add() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit("addNewItem", this.item);
        }
      });
    },
    update() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit("update", this.item);
        }
      });
    },
    cancel() {
      this.$emit("cancel", this.item);
    },
  },
};
</script>
