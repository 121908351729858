import { $themeConfig } from '@themeConfig'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    layout: {
      isRTL: $themeConfig.layout.isRTL,
      skin: localStorage.getItem('vuexy-skin') || $themeConfig.layout.skin,
      routerTransition: $themeConfig.layout.routerTransition,
      type: $themeConfig.layout.type,
      contentWidth: $themeConfig.layout.contentWidth,
      menu: {
        hidden: $themeConfig.layout.menu.hidden,
      },
      navbar: {
        type: $themeConfig.layout.navbar.type,
        backgroundColor: $themeConfig.layout.navbar.backgroundColor,
      },
      footer: {
        type: $themeConfig.layout.footer.type,
      },
      logo:'',
    },
    locale: localStorage.getItem('locale') || 'de'
  },
  getters: {},
  mutations: {
    TOGGLE_RTL(state) {
      state.layout.isRTL = !state.layout.isRTL
      document.documentElement.setAttribute('dir', state.layout.isRTL ? 'rtl' : 'ltr')
    },
    UPDATE_SKIN(state, skin) {
      state.layout.skin = skin

      // Update value in localStorage
      localStorage.setItem('vuexy-skin', skin)

      // Update DOM for dark-layout
      if (skin === 'dark') document.body.classList.add('dark-layout')
      else if (document.body.className.match('dark-layout')) document.body.classList.remove('dark-layout')
      this.dispatch('appConfig/set_settings')
    },
    UPDATE_ROUTER_TRANSITION(state, val) {
      state.layout.routerTransition = val
    },
    UPDATE_LAYOUT_TYPE(state, val) {
      state.layout.type = val
    },
    UPDATE_CONTENT_WIDTH(state, val) {
      state.layout.contentWidth = val
    },
    UPDATE_NAV_MENU_HIDDEN(state, val) {
      state.layout.menu.hidden = val
    },
    UPDATE_NAVBAR_CONFIG(state, obj) {
      Object.assign(state.layout.navbar, obj)
    },
    UPDATE_FOOTER_CONFIG(state, obj) {
      Object.assign(state.layout.footer, obj)
    },
    UPDATE_LOCALE(state, locale) {
      state.locale = locale
      localStorage.setItem('locale', locale)
    },
  },
  actions: {
    get_settings() {
      return new Promise((resolve, reject) => {
        axios
          .get('/site-settings')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    async set_settings(state){
      await this.dispatch('appConfig/get_settings').then(response => {
        let logo = state.state.layout.skin === 'dark' ? response.data.dark_logo : response.data.light_logo
        $themeConfig.app.appLogoImage = axios.defaults.baseURL + '/' + logo
        $themeConfig.layout.routerTransition = response.data.router_transition
        $themeConfig.layout.menu.isCollapsed = response.data.menu_collapsed === 'true'
        $themeConfig.layout.navbar.type = response.data.navbar_type
        $themeConfig.layout.navbar.backgroundColor = response.data.navbar_color
        $themeConfig.layout.footer.type = response.data.footer_type
        state.state.layout.routerTransition = $themeConfig.layout.routerTransition
        state.state.layout.logo = $themeConfig.app.appLogoImage
        this.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', $themeConfig.layout.menu.isCollapsed)
        state.state.layout.navbar.type = $themeConfig.layout.navbar.type
        state.state.layout.navbar.backgroundColor = $themeConfig.layout.navbar.backgroundColor
        state.state.layout.footer.type = $themeConfig.layout.footer.type
      })
    },

  },
}
