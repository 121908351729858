<template>
  <b-card title="">
    <div id="printSection"></div>
    <b-row v-if="loading">
      <b-col md="12" class="text-center">
        <b-spinner style="width: 3rem; height: 3rem" />
      </b-col>
    </b-row>
    <div class="w-100 text-right" v-if="!loading">
      <b-modal
        id="type-form-modal"
        centered
        :title="modalTitle"
        hide-footer
        ref="form-modal"
        size="lg"
      >
        <ticket-form
          @addNewItem="addNewItem"
          @cancel="$refs['form-modal'].hide()"
          :item="updateItem"
        ></ticket-form>
      </b-modal>

      <b-modal
        id="ticket-details-modal"
        centered
        :title="modalTitle"
        hide-footer
        ref="ticket-details-modal"
        size="xl"
      >
        <ticket-details
          @cancel="$refs['ticket-details-modal'].hide()"
          :ticket="updateItem"
          @updateTicket="updateTicket"
        ></ticket-details>
      </b-modal>
      <portal to="breadcrumb-right">
        <b-button
          @click = "$router.push({name: 'create-ticket'})"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          >{{ $t("Yeni Bilet Oluştur") }}</b-button
        >
      </portal>
    </div>
    <b-row v-if="!loading">
      <b-col md="5" cols="12" class="mt-2 mb-2">
        <b-input-group size="sm">
          <b-form-input
            id="filter-input"
            v-model="filter"
            type="search"
            :placeholder="$t('Ara')"
          ></b-form-input>

          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">{{
              $t("Temizle")
            }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>

      <b-col md="4" cols="12" class="mt-2 mb-2 d-none d-md-block">
        <v-select
            v-model="selected"
            multiple
            label="title"
            :placeholder="$t('Sütuna Göre Arayın')"
            :options="options"
          >
          <template v-slot:no-options>
            <div>{{ $t("nothing_found") }}</div>
          </template>
        </v-select> 
      </b-col>
      <b-col md="3" cols="12" class="mt-2 mb-2 text-center text-md-right">
      
        <vue-json-excel style="display:inline-block" :data="filteredItems" :fields="xlsFields" :name="$t('Biletlerim') +'.xls'">
          <b-button class="mr-1" size="sm" variant="outline-primary" v-b-tooltip.hover.top="$t('excell')">
            <img :src="xlsIcon" style="width:18px" class="cursor-pointer">
          </b-button>
        </vue-json-excel>

      <b-button variant="outline-info" size="sm" v-b-tooltip.hover.top="$t('print')" @click="print">
            <feather-icon
              icon="PrinterIcon"
              size="18"
            />
      </b-button>
      </b-col>
    </b-row>
    <b-table
      v-if="!loading"
      stacked
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      ref="tickets"
      sticky-header
      :filter-function="filterFunction"
      class="d-block d-lg-none"
      tbody-tr-class="border-bottom-primary border-top-primary"
    >
      <template #cell(priority)="row">
        <b-badge v-if="row.item.priority == 0" variant="secondary">{{
          $t("Düşük")
        }}</b-badge>
        <b-badge v-if="row.item.priority == 1" variant="primary">{{
          $t("Orta")
        }}</b-badge>
        <b-badge v-if="row.item.priority == 2" variant="warning">{{
          $t("Yüksek")
        }}</b-badge>
        <b-badge v-if="row.item.priority == 3" variant="danger">{{
          $t("Çok Yüksek")
        }}</b-badge>
      </template>
      <template #cell(status)="row">
        <b-badge v-if="row.item.status == 0" variant="info">{{
          $t("Yeni")
        }}</b-badge>
        <b-badge v-if="row.item.status == 1" variant="success">{{
          $t("Yanıtlandı")
        }}</b-badge>
        <b-badge v-if="row.item.status == 2" variant="info">{{
          $t("Yeni Mesaj")
        }}</b-badge>
        <b-badge v-if="row.item.status == 3" variant="secondary">{{
          $t("Kapandı")
        }}</b-badge>
      </template>
      <template #cell(department)="row">
        {{ row.item.department.name }}
      </template>
      <template #cell(id)="row"> #{{ row.item.id }} </template>
      <template #cell(subject)="row">
        {{ row.item.subject.slice(0, 20)
        }}{{ row.item.subject.length > 15 ? "..." : "" }}
      </template>
      <template #cell(actions)="row">
        <b-button class="my-auto"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          @click="$router.push('/tickets/details/' + row.item.id)"
          v-b-tooltip.hover.top="$t('Görüntüle')"
          size="sm">
            <feather-icon
          icon="ArrowRightCircleIcon"
          size="18"
          />
        </b-button>
        <!--
        <b-button
          class="mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="viewModal(row.item)"
          v-b-tooltip.hover.top="$t('Görüntüle')"
          size="sm"
          v-b-modal.ticket-details-modal
        >
          <feather-icon icon="EyeIcon" size="18" />
        </b-button>
        -->
      </template>

    </b-table>
    <b-table
      v-if="!loading"
      responsive
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
      ref="tickets"
      sticky-header
      :filter-function="filterFunction"
      class="d-none d-lg-block"
    >
      <template #cell(priority)="row">
        <b-badge v-if="row.item.priority == 0" variant="secondary">{{
          $t("Düşük")
        }}</b-badge>
        <b-badge v-if="row.item.priority == 1" variant="primary">{{
          $t("Orta")
        }}</b-badge>
        <b-badge v-if="row.item.priority == 2" variant="warning">{{
          $t("Yüksek")
        }}</b-badge>
        <b-badge v-if="row.item.priority == 3" variant="danger">{{
          $t("Çok Yüksek")
        }}</b-badge>
      </template>
      <template #cell(status)="row">
        <b-badge v-if="row.item.status == 0" variant="info">{{
          $t("Yeni")
        }}</b-badge>
        <b-badge v-if="row.item.status == 1" variant="success">{{
          $t("Yanıtlandı")
        }}</b-badge>
        <b-badge v-if="row.item.status == 2" variant="info">{{
          $t("Yeni Mesaj")
        }}</b-badge>
        <b-badge v-if="row.item.status == 3" variant="secondary">{{
          $t("Kapandı")
        }}</b-badge>
      </template>
      <template #cell(department)="row">
        {{ row.item.department.name }}
      </template>
      <template #cell(id)="row"> #{{ row.item.id }} </template>
      <template #cell(subject)="row">
        {{ row.item.subject.slice(0, 20)
        }}{{ row.item.subject.length > 15 ? "..." : "" }}
      </template>
      <template #cell(actions)="row">
        <b-button class="my-auto"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          @click="$router.push('/tickets/details/' + row.item.id)"
          v-b-tooltip.hover.top="$t('Görüntüle')"
          size="sm">
            <feather-icon
          icon="ArrowRightCircleIcon"
          size="18"
          />
        </b-button>
        <!--
        <b-button
          class="mr-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="viewModal(row.item)"
          v-b-tooltip.hover.top="$t('Görüntüle')"
          size="sm"
          v-b-modal.ticket-details-modal
        >
          <feather-icon icon="EyeIcon" size="18" />
        </b-button>
        -->
      </template>

    </b-table>
    <b-row v-if="!loading">
      <b-col cols="12" sm="6" class="text-center text-sm-left mb-1 mt-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">{{
            $t("Sayfa Başı")
          }}</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="[3, 5, 10, 25, 50]"
            style="width:60px;"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" class="mb-1 mt-1">
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          align="right"
          :total-rows="totalRows"
          class="my-0 d-sm-flex d-none"
        />
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          align="center"
          :total-rows="totalRows"
          class="my-0 d-sm-none d-flex"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BCard,
  BSpinner,
  BButton,
  BModal,
  VBModal,
  BBadge,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BPagination,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import TicketForm from "./TicketForm.vue";
import TicketDetails from "./TicketDetails.vue";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VSelect from "vue-select";
import VueJsonExcel from 'vue-json-excel'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BSpinner,
    BButton,
    BModal,
    BBadge,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BPagination,
    VSelect,
    TicketForm,
    TicketDetails,
    VueJsonExcel
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  watch: {
    selected: {
      handler() {
        this.filterOn = Array.from(
          this.selected.map((item) => item.value).values()
        );
      },
    },
  },
  data() {
    return {
      interval:null,
      items: null,
      filteredItems:null,
      loading: 1,
      updateItem: null,
      modalTitle: "",
      filterOn: [],
      selected: [],
      filter: "",
      perPage: 5,
      currentPage: 1,
      totalRows: 0,
      fields: [
        { key: "id", label: this.$t("Bilet No"), sortable: true },
        { key: "date_time", label: this.$t("Oluşturulma Tarihi"), sortable: true },
        { key: 'last_reply_at', label: this.$t('Son Cevap Tarihi'), sortable:true},
        { key: "subject", label: this.$t("Konu"), sortable: true },
        {key: 'priority', label: this.$t('Öncelik'), sortable:true, filterByFormatted:true,
          formatter:(value) => {
            switch(parseInt(value)){
              case 0 : return this.$t('Düşük')
              case 1 : return this.$t('Orta')
              case 2 : return this.$t('Yüksek')
              case 3 : return this.$t('Çok Yüksek')
            }
          }
        },
        {key: 'department', label: this.$t('Departman'), sortable:true},
        {key: 'status', label: this.$t('Durum'), sortable:true, filterByFormatted:true,
          formatter:(value) => {
            switch(parseInt(value)){
              case 0 : return this.$t('Yeni')
              case 1 : return this.$t('Yanıtlandı')
              case 2 : return this.$t('Yeni Mesaj')
              case 3 : return this.$t('Kapandı')
            }
          }
        },
        { key: "actions", label: this.$t("İşlemler"), tdClass:'noprint', thClass:'noprint' },
      ],
      options: [
        { value: "subject", title: this.$t("Konu") },
        { value: "priority", title: this.$t("Öncelik") },
        { value: "department", title: this.$t("Departman") },
        { value: "status", title: this.$t("Durum") },
      ],
      xlsIcon:require('@/assets/images/excell.png'),
      xlsFields:{
        'id': this.$t('Bilet No'),
        'owner.full_name': this.$t('Oluşturan'),
        'date_time': this.$t('Oluşturulma Tarihi'),
        'last_reply_at':this.$t('Son Cevap Tarihi'),
        'subject': this.$t('Konu'),
        'priority': this.$t('Öncelik'),
        'department.name': this.$t('Departman'),
        'status': this.$t('Durum'),
      }
    };
  },
  mounted() {
    let reversed = {}
    Object.keys(this.xlsFields).forEach(key => {
      if(key == "priority" || key == "status"){
          reversed[this.xlsFields[key]] = {
            field: key,
            callback: (value) => {
              switch(parseInt(value)){
                case 0: return key == 'priority' ? this.$t('Düşük') : this.$t('Yeni')
                case 1: return key == 'priority' ? this.$t('Orta') : this.$t('Yanıtlandı')
                case 2: return key == 'priority' ? this.$t('Yüksek') : this.$t('Yeni Mesaj')
                case 3: return key == 'priority' ? this.$t('Çok Yüksek') : this.$t('Kapandı')
              }
            }
          }
      }else{
        reversed[this.xlsFields[key]] = key
      }
    });
    this.xlsFields = Object.assign({}, reversed)
    this.$store.dispatch("tickets/list_mine").then((response) => {
      this.items = response.data;
      this.filteredItems = response.data
      this.loading = 0;
      this.totalRows = this.items.length;
      let reachThis = this
      this.interval = setInterval(function(){
        reachThis.$store.dispatch('tickets/list_mine').then(response => {
          reachThis.items = response.data
        })
      },10000)
    });
  },

  beforeDestroy(){
    clearInterval(this.interval)
  },

  methods: {
    filterFunction(item, search){
      if(!this.filterOn.length){
        return (
          item.id.toString().includes(search.toLowerCase()) ||
          this.fields?.find(f => f.key === 'created_by')?.formatter('','',item)?.toLowerCase().includes(search.toLowerCase()) ||
          item.subject?.toLowerCase().includes(search.toLowerCase()) ||
          this.fields.find(f => f.key === 'priority')?.formatter(item.priority)?.toLowerCase().includes(search.toLowerCase()) ||
          item.department?.name?.toLowerCase().includes(search.toLowerCase()) ||
          item.date_time?.toLowerCase().includes(search.toLowerCase()) ||
          item.last_reply_at?.toLowerCase().includes(search.toLowerCase()) ||
          this.fields?.find(f => f.key === 'status').formatter(item.status).toLowerCase().includes(search.toLowerCase()) 
        )
      }else{
        return(
          (this.filterOn.includes('created_by') && this.fields?.find(f => f.key === 'created_by')?.formatter('','',item)?.toLowerCase().includes(search.toLowerCase())) ||
          (this.filterOn.includes('subject') && item.subject?.toLowerCase().includes(search.toLowerCase())) ||
          (this.filterOn.includes('priority') && this.fields.find(f => f.key === 'priority')?.formatter(item.priority)?.toLowerCase().includes(search.toLowerCase())) ||
          (this.filterOn.includes('department') && item.department?.name?.toLowerCase().includes(search.toLowerCase())) ||
          (this.filterOn.includes('status') && this.fields?.find(f => f.key === 'status').formatter(item.status).toLowerCase().includes(search.toLowerCase()))

        )
      }

    },
    updateTicket(ticket) {
      let index = this.items.findIndex((item) => item.id == ticket.id);
      this.items[index] = ticket;
      this.updateItem = Object.assign({}, ticket);
      this.$refs.tickets.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.filteredItems = filteredItems
      this.currentPage = 1;
    },
    newItem() {
      this.updateItem = {
        subject: "",
        priority: 0,
        department_id: null,
        status: 0,
        message: "",
        files: null,
      };
      this.modalTitle = this.$t("Yeni Bilet");
    },

    viewModal(item) {
      this.updateItem = Object.assign({}, item);
      this.modalTitle = this.$t("Bileti Görüntüle");
    },
    async addNewItem(item) {
      if (!this.loading) {
        this.loading = 1;
        this.$refs["form-modal"].hide();
        let formData = new FormData();
        formData.append("subject", item.subject);
        formData.append("message", item.message);
        formData.append("priority", item.priority);
        formData.append("department_id", item.department_id);
        formData.append("status", 0);
        if (item.files) {
          item.files.forEach((file) => formData.append("files[]", file));
        }
        await axios
          .post("tickets/create", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            this.items.unshift(response.data);

            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Başarıyla Eklendi"),
                icon: "CheckIcon",
                variant: "success",
                text: `${this.$t("Yeni Bilet")} ${response.data.name} ${this.$t(
                  "Başarıyla Eklendi"
                )}`,
              },
            });
            this.loading = 0;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Birşeyler Ters Gitti"),
                icon: "XIcon",
                variant: "danger",
                text: `${this.$t("Yeni Bilet")} ${this.$t(
                  "Eklenirken Bir Hata Oluştu:"
                )} ${error.response.data.errors}`,
              },
            });
            this.loading = 0;
          });
      }
    },

    print(){
      document.body.classList.add('printbody')
      let $printSection = document.getElementById("printSection");
      $printSection.innerHTML = this.$refs.tickets.$el.innerHTML;
      window.print()
      document.body.classList.remove('printbody')
    },

  },
};
</script>
<style>
@media screen {
    #printSection {
        display: none;
    }
}
@media print {
    .printbody * {
        visibility:hidden;
    }
    #printSection, #printSection * {
        visibility:visible;
    }
    #printSection {
        position:absolute;
        left:0;
        top:0;
    }

    .noprint{
      display:none;
    }

}
</style>