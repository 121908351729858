<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div>
    <b-row v-if="loading">
      <b-col md="12" class="text-center">
        <b-spinner style="width: 3rem; height: 3rem" />
      </b-col>
    </b-row>
    <div style="height: inherit" v-if="!loading">
      <div
        class="body-content-overlay"
        :class="{ show: mqShallShowLeftSidebar }"
        @click="mqShallShowLeftSidebar = false"
      />

      <!-- Email List -->
      <div class="app-email-list">
        <div
          class="email-app-details"
          style="transform: unset; visibility: unset"
        >
          <div class="email-detail-header">
            <!-- Header: Left -->
            <div class="email-header-left d-flex align-items-center">
              <h4 class="email-subject mb-0">
                #{{ ticket.id }} - {{ ticket.subject }}
              </h4>
            </div>

            <!-- Header: Right -->
            <div class="email-header-right ml-2 pl-1">
              <b-badge
                pill
                class="text-capitalize mr-50"
                :variant="`light-${resolvePriorityColor(ticket)}`"
              >
                {{ resolvePriorityName(ticket) }}
              </b-badge>

              <b-badge
                pill
                class="text-capitalize mr-50"
                :variant="`light-${resolveStatusColor(ticket)}`"
              >
                {{ resolveStatusName(ticket) }}
              </b-badge>
            </div>
          </div>

          <vue-perfect-scrollbar class="email-scroll-area scroll-area" ref="sb">
            <!-- Email Thread -->

            <b-row class="d-xl-none d-lg-none">
              <b-col cols="12">
                <b-card class="w-100 mt-2">
                  <b-row>
                    <b-col cols="6" sm="6" md="3" class="mt-1">
                      {{ $t("Oluşturan") }} <br> <b>{{ ticket.owner.full_name }}</b>
                    </b-col>
                    <b-col cols="6" sm="6" md="3" class="mt-1">
                      {{ $t("Departman") }} <br> <b>{{ ticket.department.name }}</b>
                    </b-col>
                    <b-col cols="6" sm="6" md="3" class="mt-1">
                      {{ $t("Oluşturulma Tarihi") }} <br> <b>{{ ticket.date_time }}</b>
                    </b-col>
                    <b-col cols="6" sm="6" md="3" class="mt-1">
                      {{ $t("Son Cevap Tarihi") }} <br>
                      <b>{{ ticket.replies[0] ? ticket.replies[0].date_time : "" }}</b>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
              <b-col cols="12" class="text-center" >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="info"
                  class="mr-1"
                  @click="$router.push({name: $can('read', 'tickets') ? 'all-tickets' : 'my-tickets'})"
                  style="margin-top:5px; margin-bottom:5px"
                >
                  {{ $t("Geri") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  @click="showForm = !showForm"
                  v-if="ticket.status != 3"
                  style="margin-top:5px; margin-bottom:5px"
                >
                  {{ $t("Yanıtla") }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  @click="closeTicket"
                  v-if="ticket.status != 3"
                  style="margin-top:5px; margin-bottom:5px"
                >
                  {{ $t("Bileti Kapat") }}
                </b-button>
              </b-col>
            </b-row>

            <transition name="card-animation">
              <b-card class="mt-2" v-if="showForm">
                <b-form v-if="!loading" @submit.prevent>
                  <validation-observer ref="replyForm" #default="{ invalid }">
                    <b-row>
                      <b-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('Mesaj')"
                          vid="message"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Mesaj')"
                            label-for="message"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-textarea
                              id="message"
                              :placeholder="$t('Mesaj')"
                              v-model="replyMessage"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <b-col cols="12">
                        <b-form-group :label="$t('Dosyalar')" label-for="files">
                          <b-form-file
                            class="mt-1"
                            id="files"
                            accept=".jpg, .png, .jpeg, .pdf, .doc, .docx, .webp, .gif"
                            multiple
                            v-model="replyFiles"
                            :browse-text="$t('browse')"
                            :placeholder="$t('no_file_chosen')"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- submit and reset -->
                      <b-col cols="12" class="mt-2 text-center">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          type="submit"
                          variant="primary"
                          class="mr-1"
                          @click="reply()"
                          :disabled="invalid"
                        >
                          {{ $t("Yanıtla") }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </validation-observer>
                </b-form>
              </b-card>
            </transition>

            <template>
              <b-row v-for="(reply, i) in ticket.replies" :key="reply.id">
                <b-col cols="12" :class="!showForm && i == 0 ? 'mt-2' : ''">
                  <email-message-card
                    :hasBorder="reply.owner.id != ticket.owner.id"
                    :message="reply"
                    @replyUpdated="replyUpdated"
                    @replyDeleted="replyDeleted"
                  />
                </b-col>
              </b-row>
            </template>
            <b-row>
              <b-col cols="12" class="mt-2">
                <email-message-card :message="ticket" :hasBorder="false" />
              </b-col>
            </b-row>
          </vue-perfect-scrollbar>
        </div>

        <!-- Sidebar -->
        <portal to="content-renderer-sidebar-left">
          <vue-perfect-scrollbar class="email-scroll-area scroll-area">
            <div class="sidebar-left">
              <div class="sidebar">
                <div class="sidebar-content email-app-sidebar">
                  <b-row class="text-center" style="margin-left:0; margin-right:0">
                    <b-col cols="12" class="mt-1">
                      {{ $t("Oluşturan") }} <br> <b>{{ ticket.owner.full_name }}</b>
                    </b-col>
                    <b-col cols="12" class="mt-1">
                      {{ $t("Departman") }} <br> <b>{{ ticket.department.name }}</b>
                    </b-col>
                    <b-col cols="12" class="mt-1">
                      {{ $t("Oluşturulma Tarihi") }} <br> <b>{{ ticket.date_time }}</b>
                    </b-col>
                    <b-col cols="12" class="mt-1">
                      {{ $t("Son Cevap Tarihi") }} <br>
                      <b>{{ ticket.replies[0] ? ticket.replies[0].date_time : "" }}</b>
                    </b-col>
                  </b-row>
                  <div class="email-app-menu">
                    <div class="form-group-compose text-center compose-btn">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="info"
                        class="mr-1"
                        block
                        @click="$router.push({name: $can('read', 'tickets') ? 'all-tickets' : 'my-tickets'})"
                      >
                        {{ $t("Geri") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-1"
                        block
                        v-if="ticket.status != 3"
                        @click="
                          showForm = !showForm;
                          $refs['sb'].$el.scrollTop = 0;
                        "
                      >
                        {{ $t("Yanıtla") }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="secondary"
                        class="mt-1"
                        block
                        @click="closeTicket"
                        v-if="ticket.status != 3"
                      >
                        {{ $t("Bileti Kapat") }}
                      </b-button>
                    </div>
                    <h3 class="text-center">{{ $t("Dosyalar") }}</h3>
                    <div class="d-flex flex-column">
                      <h6 v-if="ticket.all_files.length" class="mt-75">
                        {{ ticket.date_time }}
                      </h6>
                      <b-link
                        v-for="(file, index) in ticket.all_files"
                        :key="index"
                        :href="file.file"
                        target="_blank"
                        class="ml-2"
                        :class="{ 'mt-75': index }"
                      >
                        <span
                          class="text-muted font-weight-bolder align-text-top"
                          >{{ file.file.split("/").slice(-1)[0] }}</span
                        >
                      </b-link>
                      <div v-if="ticket.replies.length">
                        <div
                          class="d-flex flex-column"
                          v-for="reply in ticket.replies"
                          :key="reply.id"
                        >
                          <h6 v-if="reply.all_files.length" class="mt-75">
                            {{ reply.date_time }}
                          </h6>
                          <b-link
                            v-for="(file, index) in reply.all_files"
                            :key="index"
                            :href="file.file"
                            target="_blank"
                            class="ml-2"
                            :class="{ 'mt-75': index }"
                          >
                            <span
                              class="
                                text-muted
                                font-weight-bolder
                                align-text-top
                              "
                              >{{ file.file.split("/").slice(-1)[0] }}</span
                            >
                          </b-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </vue-perfect-scrollbar>
        </portal>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BBadge,
  BCard,
  BLink,
  BButton,
  BSpinner,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import axios from "@axios";
import EmailMessageCard from "./EmailMessageCard.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: { Ripple },
  components: {
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    BLink,
    BButton,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    EmailMessageCard,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      loading: 1,
      ticket: null,
      replyFiles: null,
      replyMessage: "",
      required,
      showForm: false,
    };
  },
  mounted() {
    axios
      .get("tickets/get/" + this.$route.params.id)
      .then((response) => {
        this.ticket = response.data;
        this.loading = 0;
        if (!Object.keys(response.data).length) {
          this.$router.push({
            name: this.$can("tickets", "read") ? "all-tickets" : "my-tickets",
          });
        } else {
          if (!this.$can("tickets", "read")) {
            if (this.userData.id != response.data.created_by) {
              this.$router.push({ name: "my-tickets" });
            }
          }
        }
      })
      .catch((error) => (this.loading = 0));
  },
  setup() {
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    return {
      mqShallShowLeftSidebar,
    };
  },

  methods: {
    replyUpdated(reply) {
      this.loading = 1;
      this.ticket.replies[
        this.ticket.replies.findIndex((i) => i.id == reply.id)
      ] = reply;
      this.loading = 0;
    },

    replyDeleted(reply) {
      this.loading = 1;
      this.ticket.replies = this.ticket.replies.filter((i) => i.id != reply.id);
      this.loading = 0;
    },

    resolvePriorityColor(ticket) {
      switch (parseInt(ticket.priority)) {
        case 0:
          return "secondary";
        case 1:
          return "primary";
        case 2:
          return "warning";
        case 3:
          return "danger";
      }
    },
    resolveStatusColor(ticket) {
      switch (parseInt(ticket.status)) {
        case 0:
          return "info";
        case 1:
          return "success";
        case 2:
          return "info";
        case 3:
          return "secondary";
      }
    },
    resolvePriorityName(ticket) {
      switch (parseInt(ticket.priority)) {
        case 0:
          return this.$t("Düşük");
        case 1:
          return this.$t("Orta");
        case 2:
          return this.$t("Yüksek");
        case 3:
          return this.$t("Çok Yüksek");
      }
    },
    resolveStatusName(ticket) {
      switch (parseInt(ticket.status)) {
        case 0:
          return this.$t("Yeni");
        case 1:
          return this.$t("Yanıtlandı");
        case 2:
          return this.$t("Yeni Mesaj");
        case 3:
          return this.$t("Kapandı");
      }
    },

    async closeTicket() {
      if (!this.loading) {
        this.loading = 1;
        await axios
          .get("tickets/close-ticket/" + this.ticket.id)
          .then((response) => {
            this.ticket = response.data;
            this.loading = 0;
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Başarıyla Kapatıldı"),
                icon: "CheckIcon",
                variant: "success",
                text: `${this.$t("Bilet")} ${this.$t("Başarıyla Kapatıldı")}`,
              },
            });
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Birşeyler Ters Gitti"),
                icon: "XIcon",
                variant: "danger",
                text: `${this.$t("Bilet")} ${this.$t(
                  "Güncellenirken Bir Hata Oluştu:"
                )} ${error.response.data.errors}`,
              },
            });
            this.loading = 0;
          });
      }
    },

    async reply() {
      if (!this.loading) {
        this.loading = 1;
        let formData = new FormData();
        formData.append("ticket_id", this.ticket.id);
        formData.append("message", this.replyMessage);
        formData.append("status", 2);
        if (this.replyFiles) {
          this.replyFiles.forEach((file) => formData.append("files[]", file));
        }
        await axios
          .post("tickets/reply", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            this.ticket = response.data;
            this.replyFiles = null;
            this.replyMessage = null;
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Başarıyla Eklendi"),
                icon: "CheckIcon",
                variant: "success",
                text: `${this.$t("Yanıt")} ${response.data.name} ${this.$t(
                  "Başarıyla Eklendi"
                )}`,
              },
            });
            this.loading = 0;
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              position: "bottom-right",
              props: {
                title: this.$t("Birşeyler Ters Gitti"),
                icon: "XIcon",
                variant: "danger",
                text: `${this.$t("Yanıt")} ${this.$t(
                  "Eklenirken Bir Hata Oluştu:"
                )} ${error.response.data.errors}`,
              },
            });
            this.loading = 0;
          });
      }
    },
  },
};
</script>


<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>

<style scoped>
.card-animation-enter {
  height: 0;
  overflow: hidden;
}
.card-animation-enter-active {
  transition: all 0.5s ease;
}
.card-animation-enter-to {
  height: 300px;
  overflow: hidden;
}
.card-animation-leave {
  height: 300px;
  overflow: hidden;
}
.card-animation-leave-active {
  transition: all 0.5s ease;
}
.card-animation-leave-to {
  height: 0;
  overflow: hidden;
}
</style>