<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      md="9"
      cols="6"
    >
      <b-row>
        <b-col cols="12">
          <h2 class="content-header-title float-left mb-0 d-none d-md-block">
            {{ $t($route.meta.pageTitle) }}
          </h2>
          <h4 class="content-header-title float-left mb-0 d-block d-md-none">
            {{ $t($route.meta.pageTitle) }}
          </h4>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb class="d-none d-md-flex">
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ $t(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-right d-md-block mb-1"
      md="3"
      cols="6"
    >
    <portal-target name="breadcrumb-right">
        <!-- Anything can be here. Docs: https://portal-vue.linusb.org/guide/getting-started.html#the-basics -->
    </portal-target>
    </b-col>
    <b-col cols="12">
      <portal-target name="slider">
          <!-- Anything can be here. Docs: https://portal-vue.linusb.org/guide/getting-started.html#the-basics -->
      </portal-target>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
}
</script>
