<template>
  <b-form @submit.prevent>
    <validation-observer ref="addForm" #default="{ invalid }">
      <b-row>
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            :name="$t('Departman Adı')"
            vid="name"
            rules="required"
          >
            <b-form-group
              :label="$t('Departman Adı')"
              label-for="name"
              label-cols-md="3"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="name"
                :placeholder="$t('Departman Adı')"
                v-model="item.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="3">
          <h5 class="mt-1">{{ $t("Önizleme") }}</h5>
          <img class="w-100" v-if="item.logo" :src="preview" />
        </b-col>
        <b-col cols="9">
            <h5 class="mt-1">{{ $t("Logo") }}</h5>
            <b-form-file
              class="mt-1"
              id="logo"
              accept=".jpg, .png, .jpeg"
              v-model="logo"
              @change="onLogoChange"
              :browse-text="$t('browse')"
              :placeholder="$t('no_file_chosen')"
            />

        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4" class="mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="add"
            :disabled="invalid"
            v-if="!item.id"
          >
            {{ $t("Ekle") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="update"
            :disabled="invalid"
            v-if="item.id"
          >
            {{ $t("Kaydet") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            @click="cancel"
            variant="outline-secondary"
          >
            {{ $t("İptal") }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      logo: null,
    };
  },
  computed: {
    preview() {
      return this.logo
        ? this.item.logo
        : axios.defaults.baseURL + "/" + this.item.logo;
    },
  },
  props: ["item"],
  methods: {
    onLogoChange(e) {
      const file = e.target.files[0];
      this.item.logo = URL.createObjectURL(file);
    },
    add() {
      if(!this.logo){
        this.$swal.fire({
          title: this.$t("Logo Seçmediniz"),
          text: this.$t("department_logo_not_selected_warning"),
          icon: "warning",
          confirmButtonText: this.$t("Tamam"),
        })
        return
      }
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit("addNewItem", this.item, this.logo);
        }
      });
    },
    update() {
      this.$refs.addForm.validate().then((success) => {
        if (success) {
          this.$emit("update", this.item, this.logo);
        }
      });
    },
    cancel() {
      this.$emit("cancel", this.item);
    },
  },
};
</script>
